<ng-template #content let-modal>

</ng-template>
<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">PILIH PROMO</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="row p-2">
        <div class="promotion-filter col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row mb-2 mb-lg-4">
                        <span
                            [class]="type === 'all' ? 'promotion-filter-type col-4 text-center button-primary button-primary-bg-'+currentTheme : 'promotion-filter-type col-4 text-center'"
                            (click)="typeClick('all')">
                            All
                        </span>
                        <span
                            [class]="type === 'deposit' ? 'promotion-filter-type col-4 text-center button-primary button-primary-bg-'+currentTheme : 'promotion-filter-type col-4 text-center'"
                            (click)="typeClick('deposit')">
                            Deposit
                        </span>
                        <span
                            [class]="type === 'cashback' ? 'promotion-filter-type col-4 text-center button-primary button-primary-bg-'+currentTheme : 'promotion-filter-type col-4 text-center'"
                            (click)="typeClick('cashback')">
                            Cashback
                        </span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Enter your promo code here..."
                            (keyup)="keywordSubmit($event)">
                        <span class="input-group-text input-blue text-white">
                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        @if(promoList !== null) {
        @for (item of promoShow; track item;) {
        <div class="col-12 px-4 pb-3 promotion-item"
            [class.promotion-active]="promoCurrent !== null && promoCurrent._id === item._id"
            (click)="promoClick(item)">
            <div class="row bg-white br-2 px-2 rounded">
                <div class="promotion-left col-12 py-2 px-4 d-flex flex-column justify-content-center">
                    <div class="promotion-code-text text-center mb-1">{{item.rules.code}}</div>
                </div>
                <div class="col-12 py-2 px-2 px-lg-4 ps-lg-4 d-flex flex-column justify-content-center">
                    <div class="promotion-title">
                        {{item.rules.title}}
                    </div>
                    <div class="promotion-type">
                        {{item.type}}
                    </div>
                    <!-- <div class="promotion-valid mt-2">
                        @if(item.rules.end_date !== '') {
                        Valid until {{dateFormat(item.rules.end_date)}}
                        }
                    </div> -->
                </div>
            </div>
        </div>
        }
        }
        <div class="col-12 text-center mt-2 mt-lg-4">
            <div [class]="'btn btn-block button-primary button-primary-bg-'+currentTheme" (click)="modal.close(null)">
                TANPA PROMO</div>
        </div>
    </div>
</div>
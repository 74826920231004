<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">Tambah Catatan</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="align-items-center">
        <div class="p-4 mb-3 rounded">
            <div class="row mb-4 fs-12">
                <div class="col-12 mb-3">
                    <textarea class="form-control" rows="3" placeholder="Catatan" [(ngModel)]="transaction_note"
                        name="transaction_note"></textarea>
                </div>
                <div class="col-12">
                    <div [class]="'btn btn-block button-primary button-primary-bg-'+currentTheme"
                        (click)="popupClose()">
                        Simpan
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
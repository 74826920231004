<div class="footer" [ngStyle]="{
        'background-image': currentPath === '/' ? 'url(../template/mps-basic/assets/section/global/'+currentTheme+'/background.webp)' : '',
        'background-color': currentPath === '/' ? 'none' : '#000000'
    }">
    <div class="container-xxl m-auto">
        <div class="footer-container">
            <div ngbAccordion>
                <div ngbAccordionItem [collapsed]="false">
                    @if(data.title !== '') {
                    <h2 ngbAccordionHeader class="fs-20 fw-500">
                        <button ngbAccordionButton [innerHTML]="data.title"></button>
                    </h2>
                    }
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody class="fs-14 fw-400">
                            <div [innerHTML]="data.content"></div>
                            <!-- <ng-template>
                            </ng-template> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer-text text-center fs-14">
    © Copyright 2024 Gacor. All rights reserved.
</div>
import { Component, inject, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-referral-popup-edit',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './referral-popup-edit.component.html',
  styleUrl: './referral-popup-edit.component.scss'
})
export class ReferralPopupEditComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService
  ) { }

  private modal = inject(NgbActiveModal);
  popupClose() {
    this.modal.close();
  }

  currentTheme: any = 'gold';

  successMessage: string = '';
  errorMessage: string = '';
  editSubmit: boolean = false;
  referralCode: string = '';

  editReferralSubmit() {
    this.editSubmit = true;
    const userProfileData: any = this.auth.userProfileData;
    this.request.editReferral(this.auth.sessionValue, this.referralCode, userProfileData._id).subscribe({
      next: (v) => {
        console.log(v);
        // this.editSubmit = false;
        this.errorMessage = '';
        this.successMessage = 'Edit Referral Success.';
        setTimeout(() => {
          this.popupClose();
        }, 1000);
      },
      error: (e) => {
        console.log(e);
        this.editSubmit = false;
        this.successMessage = '';
        this.errorMessage = e.error.messages[0].message;
      },
      complete: () => { }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // this.getData();
    }
  }
}

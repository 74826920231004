@if(!preload) {
<div class="card card-game" #cardGame>
    <div class="card-game-top">
        <div class="ratio ratio-1x1">
            @if(image !== '' && image !== undefined) {
            <img [src]="image" [alt]="name" class="card-img-top">
            } @else() {
            <div class="no-image">No Image</div>
            }
        </div>

        @if(currentScreenSize === 'mobile') {
        <div [class]="'favourite favourite-'+favourite">
            <fa-icon [icon]="faStar"></fa-icon>
        </div>
        }
    </div>
    <div class="card-body">
        <div class="card-title fs-16">
            <div class="card-title-inner" #cardTitleInner [class.animate]="cardTitleInnerWidth > (cardGameWidth-33)">
                {{name}}
            </div>
        </div>
        <div class="provider fs-12">{{provider}}</div>
        @if(percent !== undefined) {
        <div class="percent">
            <div class="progress">
                <div class="progress-bar" [style]="{width: percent+'%'}"></div>
            </div>
            <div class="text">
                {{percent}}%
            </div>
        </div>
        }
    </div>
</div>
} @else {
<div class="card card-game placeholder-glow" #cardGame>
    <div class="card-game-top">
        <div class="ratio ratio-1x1 placeholder"></div>
    </div>
    <div class="card-body">
        <div class="card-title placeholder">
            <div class="card-title-inner" #cardTitleInner [class.animate]="cardTitleInnerWidth > (cardGameWidth-33)">
                Games Games Games
            </div>
        </div>
        <div class=" provider placeholder">Provider Provider</div>
        <div class="percent">
            <div class="progress placeholder">
                <div class="progress-bar" [style]="{width: '90%'}"></div>
            </div>
            <div class="text placeholder">
                90%
            </div>
        </div>
    </div>
</div>
}
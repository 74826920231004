import { Component, inject, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-bank-create-popup',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './bank-create-popup.component.html',
  styleUrl: './bank-create-popup.component.scss'
})
export class BankCreatePopupComponent {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public request: RequestService,
    public auth: AuthService,
    private data: DataService
  ) { }

  currentTheme: string = 'gold';
  modal = inject(NgbActiveModal);

  bank: any = '';
  account_name: string = '';
  account_number: string = '';
  userProfileData: any = null;

  bankList: any = [];

  successMessage: string = '';
  errorMessage: string = '';
  isSubmit: boolean = false;

  playerBankList: any = null;

  createBankAccount(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.bank || !this.account_number) {
        this.errorMessage = 'Bank or Nomor Rekening is Required.';
        this.isSubmit = false;
        return;
      } else {
        this.isSubmit = false;
        this.request.createAccountBank(this.auth.sessionValue, this.bank, this.account_name, this.account_number, this.userProfileData._id).subscribe({
          next: (v: any) => {
            if (v.code == 200) {
              this.isSubmit = false;
              this.successMessage = 'Daftar Akun Bank is Success.';

              this.request.getBankPlayerList(this.auth.sessionValue, this.userProfileData._id).subscribe({
                next: (v2) => {
                  this.playerBankList = v2;

                  setTimeout(() => {
                    this.modal.close(this.playerBankList);
                  }, 2000);
                },
                error: (e2) => {
                  console.log(e2);
                },
                complete: () => { }
              });
            }
          },
          error: (e) => {
            this.isSubmit = false;
            this.errorMessage = e.error.messages[0].message;
          }
        })
      }
    }
  }

  popupClose() {
    this.modal.close(this.playerBankList);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const bankList: any = this.data.bankRegisterJSONData;
      for (const value of bankList.records) {
        if (value.status === true) {
          this.bankList.push(value);
        }
      }
      this.userProfileData = this.auth.userProfileData;
      this.account_name = this.userProfileData.first_name;
    }
  }
}

import { Component, inject, PLATFORM_ID, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-login-popup',
  standalone: true,
  imports: [FontAwesomeModule, FormsModule, RouterModule, CommonModule],
  templateUrl: './login-popup.component.html',
  styleUrl: './login-popup.component.scss'
})
export class LoginPopupComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';
  currentTheme: string = 'gold';

  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  username: string = '';
  password: string = '';
  isPasswordVisible: boolean = false;
  errorMessage: string = '';
  loginSubmit: boolean = false;

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public close(): void {
    const activatedRoute: any = this.activatedRoute;
    if (activatedRoute.queryParams._value.url !== undefined) {
      this.router.navigate([activatedRoute.queryParams._value.url]);
    } else {
      this.router.navigate(['/']);
    }
  }

  public login(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.errorMessage = '';
      this.loginSubmit = true;
      if (!this.username || !this.password) {
        setTimeout(() => {
          this.errorMessage = 'Username or Password is Required.';
          this.loginSubmit = false;
        }, 500);
        return;
      }
      this.request.login(this.username, this.password).subscribe({
        next: (v) => {
          if (v.code == 200) {
            this.loginSubmit = false;
            this.auth.setLogin(v.data, {});
            this.activeModal.dismiss();
            const activatedRoute: any = this.activatedRoute;
            if (activatedRoute.queryParams._value.url !== undefined) {
              this.router.navigate(['/pin'], { queryParams: { url: activatedRoute.queryParams._value.url } });
            } else {
              this.router.navigate(['/pin']);
            }
          }
        },
        error: (e) => {
          this.loginSubmit = false;
          this.errorMessage = e.error.messages[0].message;
        }
      })
    }
  }

  activeModal = inject(NgbActiveModal);
}

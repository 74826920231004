<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">PILIH REKENING ANDA</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="row p-2">
        <div class="promotion-filter col-12 mb-4">
            <div class="row">
                <div class="col-12">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search Bank"
                            (keydown.enter)="keywordSubmit($event)">
                        <span class="input-group-text button-alt1">
                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        @if(bankList !== null) {
        @for (item of bankShow; track item;) {
        <div class="col-12 px-4 pb-3 promotion-item"
            [class.promotion-active]="bankCurrent !== null && bankCurrent._id === item._id" (click)="bankClick(item)">
            <div class="row br-2 p-2 py-lg-3 px-lg-2 rounded">
                <div class="col-3 col-lg-1 bank-left pe-lg-2 mb-lg-0 mb-2 d-flex flex-column justify-content-center">
                    <img [src]="item.system_bank.images.small" />
                </div>
                <div class="col-9 col-lg-11 ps-lg-2 d-flex flex-column justify-content-center">
                    <div class="bank-account-name fs-12">
                        {{item.system_bank.name}}
                    </div>
                    <div class="bank-account-number fs-16">
                        {{item.account_name}} - {{item.account_number}}
                    </div>
                </div>
            </div>
        </div>
        }
        }
        <div class="col-12">
            <div [class]="'btn btn-primary btn-block btn-create-account button-primary button-primary-bg-'+currentTheme"
                (click)="bankCreateOpen()">BUAT REKENING BARU</div>
        </div>
    </div>
</div>
<div class="page-home">
    <app-header activeMenu="home"></app-header>
    @if(islogin && currentScreenSize === 'mobile') {
    <div class="saldo-mobile {{isFixed}}">
        <div class="row">
            <div class="col">
                <div class="saldo-mobile-item">
                    <fa-icon [icon]="faUserCircle"></fa-icon>
                    <div class="info">
                        <div class="label">Username</div>
                        <div class="value">
                            {{profile.login_id}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="saldo-mobile-item">
                    <fa-icon [icon]="faWallet"></fa-icon>
                    <div class="info">
                        <div class="label">Balance</div>
                        <div class="value">
                            <span>Rp</span>
                            <span class="balance">{{balance}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    <app-slideshow1 [data]="slideshowData"></app-slideshow1>
    <div class="bg-black d-block d-lg-none">
        <div class="category-menu container-xxl m-auto">
            <a routerLink="/category/slots">
                <img [src]="'../template/mps-basic/assets/section/home/category-menu/'+currentTheme+'/slot-games.webp'"
                    alt="slot games" />
            </a>
            <a routerLink="/category/live-casino">
                <img [src]="'../template/mps-basic/assets/section/home/category-menu/'+currentTheme+'/live-casino.webp'"
                    alt="live-casino" />
            </a>
            <a routerLink="/category/fish-hunter">
                <img [src]="'../template/mps-basic/assets/section/home/category-menu/'+currentTheme+'/arcade-games.webp'"
                    alt="arcade games" />
            </a>
            <a routerLink="/category/sportbook">
                <img [src]="'../template/mps-basic/assets/section/home/category-menu/'+currentTheme+'/live-sportbook.webp'"
                    alt="sport book" />
            </a>
        </div>
    </div>
    @if(!islogin && currentScreenSize === 'mobile') {
    <div class="menu-login-mobile {{islogin}} {{currentScreenSize}}">
        <a class="custom-button fs-12" (click)="redirectLogin()">
            <div class="login">Login</div>
        </a>
        <a class="custom-button fs-12" routerLink="/register">
            <div class="register">Register</div>
        </a>
    </div>
    }
    <app-popular-games [data]="popularGamesData"></app-popular-games>
    <app-new-games [data]="newGamesData"></app-new-games>
    <app-jackpot [value]="5000000"></app-jackpot>
    <app-all-games [autoScroll]="false"></app-all-games>
    <div class="payment-method">
        <div class="payment-title text-center mb-4">
            <div class="m-auto button-title fs-20 fw-600 fs-mb-14">
                PAYMENT METHOD
            </div>
        </div>
        <div class="container-xxl m-auto">
            <div class="payment-method-inner">
                <swiper-container
                    breakpoints='{"200": {"slidesPerView": 3}, "576": {"slidesPerView": 4}, "768": {"slidesPerView": 6}, "992": {"slidesPerView": 8}}'
                    loop="true" navigation="true">
                    @for (item of paymentMethodData; track item.id;) {
                    <swiper-slide class="swiper-item">
                        <div class="d-flex flex-column justify-content-center">
                            <img class="bg-white p-2" [src]="item.images.small" [alt]="item.name" />
                        </div>
                    </swiper-slide>
                    }
                </swiper-container>
            </div>
        </div>
    </div>
    <app-footer [data]="footerData"></app-footer>
</div>
<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">PEMBAYARAN</h4>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="text-center text-white">
        Total
        <div>
            <h4>Rp {{total}}</h4>
        </div>
        <div class="mt-2">
            <div class="p-2">
                <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="qrisCallback.qris" [width]="256"
                    [errorCorrectionLevel]="'M'"></qrcode>
                <a [href]="qrCodeDownloadLink" download="qrcode">Download</a>
            </div>
        </div>
        <button type="submit" class="btn btn-blue btn-block mt-4" (click)="popupClose()">
            Cek Status Pembayaran
        </button>
        <div class="mt-2 countdown">
            Berlaku Hingga <span>{{ minutesShow }}:{{ secondsShow }}</span>
        </div>
    </div>
</div>
<div class="modal-container" #modalContainer>
    <div class="modal-body p-0">
        <div class="row">
            <div class="col-12 col-lg-5 position-relative">
                @if(currentScreenSize === 'desktop') {
                <div class="register-left"
                    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/register/'+currentTheme+'/background.png)'}">
                    <img class="logo" src="../template/mps-basic/assets/images/logo.png" />
                    <div class="info accent-primary-text">
                        <b>REGISTER NOW!</b><br />
                        AND WIN THE JACKPOT
                    </div>
                </div>
                }
                @else if(currentScreenSize === 'mobile') {
                <div class="register-left">
                    <img class="image-bg"
                        [src]="'../template/mps-basic/assets/section/register/'+currentTheme+'/background2.png'" />
                    <img class="logo" src="../template/mps-basic/assets/images/logo.png" />
                    <div class="info text-white">
                        <b>REGISTER NOW!</b><br />
                        AND WIN THE JACKPOT
                    </div>
                </div>
                }
            </div>
            <div class="col-12 col-lg-7">
                <div class="register-right p-4 p-lg-5">
                    <div class="title mb-4">
                        <span class="fs-20 fw-400 fs-mb-14 accent-alt4-text">DAFTAR</span>
                        <!-- @if(currentScreenSize === 'desktop') {
                        <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
                        } -->
                    </div>
                    @if(successMessage !== '') {
                    <div class=" alert alert-success p-2" role="alert">
                        {{successMessage}}
                    </div>
                    }
                    <form (submit)="step3Submit()">
                        @if(errorMessage1 !== '') {
                        <div class="alert alert-danger p-2" role="alert">
                            {{errorMessage1}}
                        </div>
                        }
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">Username</label>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="Masukan Username"
                                    [(ngModel)]="username" name="username">
                            </div>
                        </div>
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">No. Handphone</label>
                            <div class="col-12 col-lg-8">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <img src="../../assets/orianna/images/flag-id.png" /> +62
                                        </span>
                                    </div>
                                    <input type="number" class="form-control" placeholder="Masukan No. Handpone"
                                        [(ngModel)]="phone" name="phone">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">Refferal Code</label>
                            <div class="col-12 col-lg-8">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Masukan Kode Referral"
                                        [(ngModel)]="referral" name="referral" [disabled]="referralDisabled">
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-green"
                                            (click)="checkReferral()">Validate</button>
                                    </div>
                                </div>
                                @if(referralValid === true) {
                                <div class="alert alert-success p-1 mt-1" role="alert">
                                    Refferal Code is Valid.
                                </div>
                                } @else if (referralValid === false) {
                                <div class="alert alert-danger p-1 mt-2" role="alert">
                                    Refferal Code is Not Valid.
                                </div>
                                }
                            </div>
                        </div>
                        <hr style="border: 1px solid white;" />
                        @if(errorMessage2 !== '') {
                        <div class="alert alert-danger p-2" role="alert">
                            {{errorMessage2}}
                        </div>
                        }
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">Password</label>
                            <div class="col-12 col-lg-8 password">
                                <input type="password" class="form-control"
                                    [type]="isPasswordVisible ? 'text' : 'password'" placeholder="Buat Password"
                                    [(ngModel)]="password" name="password" required>
                                <fa-icon (click)="togglePasswordVisibility('password')"
                                    [icon]="isPasswordVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                            </div>
                        </div>
                        @if(websiteJSONData.configuration.pin) {
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">PIN</label>
                            <div class="col-12 col-lg-8 password">
                                <input maxlength="6" type="password" class="form-control"
                                    [type]="isPinVisible ? 'text' : 'password'" placeholder="PIN" [(ngModel)]="pin"
                                    name="pin" (input)="pinInput($event)" required>
                                <fa-icon (click)="togglePasswordVisibility('pin')"
                                    [icon]="isPinVisible ? faEyeSlash : faEye" class="fa-1x"></fa-icon>
                            </div>
                        </div>
                        }
                        <hr style="border: 1px solid white;" />
                        @if(errorMessage3 !== '') {
                        <div class="alert alert-danger p-2" role="alert">
                            {{errorMessage3}}
                        </div>
                        }
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">Bank</label>
                            <div class="col-12 col-lg-8">
                                <select class="form-select" [(ngModel)]="bank" name="bank">
                                    <option value="">Pilih Bank Anda</option>
                                    @for (item of bankList; track item._id;) {
                                    <option [value]="item._id">{{item.name}}</option>
                                    }
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">Rekening</label>
                            <div class="col-12 col-lg-8">
                                <input type="text" class="form-control" placeholder="Masukan Nama Rekening Anda"
                                    [(ngModel)]="account_name" name="account_name">
                            </div>
                        </div>
                        <div class="form-group row mb-3 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label">No. Rekening</label>
                            <div class="col-12 col-lg-8">
                                <input type="number" class="form-control" placeholder="Masukan Rekening Anda"
                                    [(ngModel)]="account_number" name="account_number">
                            </div>
                        </div>
                        <div class="form-group row mb-2 d-flex align-items-center">
                            <label class="col-12 col-lg-4 mb-2 mb-lg-0 label"></label>
                            <div class="col-12 col-lg-8">
                                <div class="row">
                                    <div class="col-6">
                                        <button type="button" class="btn btn-block btn-outline cancel"
                                            (click)="close()">
                                            BATAL
                                        </button>
                                    </div>
                                    <div class="col-6">
                                        <button type="submit"
                                            [class]="'submit btn btn-block button-primary button-primary-bg-'+currentTheme"
                                            [disabled]="login3Submit || login3Success">
                                            @if(login3Submit) {
                                            <div class="spinner-border p-2 spinner-border-sm"></div>
                                            } @else {
                                            DAFTAR
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
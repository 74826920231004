<div class="page-withdrawal ticker-none">
    <app-header activeMenu="withdrawal" [latestNewsMobile]="false" />
    <div class="bg-button-title">
        <div class="button-title fs-20 fw-600 fs-mb-13">
            LIST TRANSACTION
        </div>
    </div>
    <div class="page-inner"
        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/global/'+currentTheme+'/background.webp)'}">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'withdrawal'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    WITHDRAWAL
                </div>
                <div class="saldo-container px-lg-4 px-0 mt-4">
                    <div class="row mb-4 px-lg-0 px-2">
                        @if(currentScreenSize === 'desktop') {
                        <div class="col-lg-3"></div>
                        <div class="col-lg-6 col-12">
                            <div class="card2"
                                [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/withdrawal/'+currentTheme+'/bg-card.webp)'}">
                                <div class="card-inner">
                                    <div class="row mb-4">
                                        <div class="col-12 col-lg-6 fs-14">
                                            @if (bank_info.player !== null) {
                                            {{bank_info.player.system_bank.name}}
                                            }
                                        </div>
                                        <div class="col-12 col-lg-6">
                                            <div class="ml-auto">
                                                @if (bank_info.player !== null) {
                                                <div
                                                    class="bank-image bg-white d-flex justify-content-center align-items-center">
                                                    <img class="bg-white px-2"
                                                        [src]="bank_info.player.system_bank.images.small" />
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="account-name fs-20">
                                        @if (bank_info.player !== null) {
                                        {{bank_info.player.account_name}}
                                        }
                                    </div>
                                    <div class="account-number fs-22">
                                        @if (bank_info.player !== null) {
                                        {{bank_info.player.account_number}}
                                        }
                                    </div>
                                </div>
                                <div class="change-account fs-20" (click)="popupOpen()">
                                    @if (bank_info.player !== null) {
                                    GANTI REKENING ANDA
                                    }
                                    @else {
                                    &nbsp;
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3"></div>
                        } @else {
                        <div class="card2"
                            [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/withdrawal/'+currentTheme+'/bg-card.webp)'}">
                            <div class="card-inner">
                                <div class="account-name fs-12">
                                    {{bank_info.player.account_name}}
                                </div>
                                <div class="account-number fs-14">
                                    {{bank_info.player.account_number}}
                                </div>
                                <div class="image-bank">
                                    <img class="bg-white px-2" [src]="bank_info.player.system_bank.images.small" />
                                </div>
                            </div>
                            <div class="change-account fs-12 p-2" (click)="popupOpen()">
                                GANTI REKENING ANDA
                            </div>
                        </div>
                        }
                    </div>
                    <div class="row">
                        @if(currentScreenSize === 'desktop') {
                        <div class="col-lg-1"></div>
                        }
                        <div class="col-lg-10 col-12">
                            @if(successMessage !== '') {
                            <div class="alert alert-success p-2" role="alert">
                                {{successMessage}}
                            </div>
                            }
                            @if(errorMessage !== '') {
                            <div class="alert alert-danger p-2" role="alert">
                                {{errorMessage}}
                            </div>
                            }
                            <form (submit)="createWithdrawSubmit()">
                                <div class="row mb-4">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <label class="mb-2 text-white">Metode Pembayaran*</label>
                                            <input type="text" class="form-control" placeholder="Metode Pembayaran"
                                                [value]="bank_info.player !== null ? bank_info.player.system_bank.name : ''"
                                                disabled>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <label class="mb-2 text-white">Nama Rekening*</label>
                                            <input type="text" class="form-control" placeholder="Nama Rekening"
                                                [value]="bank_info.player !== null ? bank_info.player.account_name : ''"
                                                disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <label class="mb-2 text-white">Nomor Rekening*</label>
                                            <input type="text" class="form-control" placeholder="Nomor Rekening"
                                                [value]="bank_info.player !== null ? bank_info.player.account_number : ''"
                                                disabled>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="form-group">
                                            <label class="mb-2 text-white">Jumlah*</label>
                                            <input type="text" class="form-control" placeholder="Jumlah" name="amount"
                                                (input)="filterNumber($event)" (keyup)="amountKeyup($event)"
                                                [(ngModel)]="amountNumberFormat">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <label class="mb-2 text-white">Note</label>
                                    <textarea class="form-control" rows="3" placeholder="Note"
                                        [(ngModel)]="transaction_note" name="transaction_note"></textarea>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-12 text-end">
                                        <div class="btn btn-black btn-disable" (click)="ResetForm()">Reset</div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <button type="submit"
                                            [class]="'btn button-primary button-primary-' + currentTheme"
                                            [disabled]="isSubmit || bank_info === null">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        @if(currentScreenSize === 'desktop') {
                        <div class="col-lg-1"></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>
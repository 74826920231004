import { ResolveFn, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { CategoryComponent } from './category/category.component';
import { SearchComponent } from './search/search.component';
import { PromotionComponent } from './promotion/promotion.component';
import { ContactComponent } from './contact/contact.component';
import { SaldoComponent } from './saldo/saldo.component';
import { TransactionComponent } from './transaction/transaction.component';
import { StatementComponent } from './statement/statement.component';
import { ReferralComponent } from './referral/referral.component';
import { ProfileComponent } from './profile/profile.component';
import { DepositComponent } from './deposit/deposit.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { AuthGuard } from './services/guard.service';
import { DataService } from './services/data.service';
import { LoginComponent } from './login/login.component';
import { PinComponent } from './pin/pin.component';
import { inject } from '@angular/core';
import { GameComponent } from './game/game.component';
import { EventComponent } from './event/event.component';
import { ProviderComponent } from './provider/provider.component';
import { MetaWebsiteService } from './services/meta.service';
import { WallComponent } from './wall/wall.component';
import { LandingComponent } from './landing/landing.component';
import { LandingDetailComponent } from './landing-detail/landing-detail.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'register',
    canActivate: [AuthGuard],
    component: RegisterComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    component: LoginComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'pin',
    canActivate: [AuthGuard],
    component: PinComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'category/:id',
    canActivate: [AuthGuard],
    component: CategoryComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'search',
    canActivate: [AuthGuard],
    component: SearchComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'providers',
    canActivate: [AuthGuard],
    component: ProviderComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'provider',
    canActivate: [AuthGuard],
    component: SearchComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'provider/:name',
    canActivate: [AuthGuard],
    component: SearchComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'promotion',
    canActivate: [AuthGuard],
    component: PromotionComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'event',
    canActivate: [AuthGuard],
    component: EventComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'landing',
    canActivate: [AuthGuard],
    component: LandingComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'landing/:id',
    canActivate: [AuthGuard],
    component: LandingDetailComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'contact',
    canActivate: [AuthGuard],
    component: ContactComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'saldo',
    canActivate: [AuthGuard],
    component: SaldoComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'transaction',
    canActivate: [AuthGuard],
    component: TransactionComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'statement',
    canActivate: [AuthGuard],
    component: StatementComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'referral',
    canActivate: [AuthGuard],
    component: ReferralComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    component: ProfileComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'deposit',
    canActivate: [AuthGuard],
    component: DepositComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'withdrawal',
    canActivate: [AuthGuard],
    component: WithdrawalComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'game/:type',
    canActivate: [AuthGuard],
    component: SearchComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'auth/:session',
    canActivate: [AuthGuard],
    component: WallComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'game/:type/:name',
    canActivate: [AuthGuard],
    component: SearchComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'real-money/:type',
    canActivate: [AuthGuard],
    component: SearchComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'play/:name',
    canActivate: [AuthGuard],
    component: GameComponent,
    resolve: [MetaWebsiteService]
  },
  {
    path: 'play/:provider/:name',
    canActivate: [AuthGuard],
    component: GameComponent,
    resolve: [MetaWebsiteService]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

<div class="page-profile">
    <app-header activeMenu="profile" />
    <form (submit)="editProfileSubmit()">
        <div class="profile-inner p-5"
            [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/global/'+currentTheme+'/background.webp)'}">
            <div class="button-title">
                PROFILE
            </div>
            @if(successMessage !== '') {
            <div class="alert alert-success p-2" role="alert">
                {{successMessage}}
            </div>
            }
            @if(errorMessage !== '') {
            <div class="alert alert-danger p-2" role="alert">
                {{errorMessage}}
            </div>
            }
            <div class="profile-title accent-alt4-text">
                PERSONAL DETAILS
            </div>
            <div class="mb-4 accent-main-text">
                Manage your name and your contact info. These personal details are privat and will not will displayed to
                other user
            </div>
            <div class="row mb-5">
                <div class="col-12 col-lg-6 mb-4">
                    <div class="form-group">
                        <label class="mb-2 accent-main-text">First Name</label>
                        <input type="text" class="form-control" placeholder="First Name" disabled
                            [value]="userProfileData.first_name">
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-4">
                    <div class="form-group">
                        <label class="mb-2 accent-main-text">Last Name</label>
                        <input type="text" class="form-control" placeholder="Last Name" disabled
                            [value]="userProfileData.last_name">
                    </div>
                </div>
            </div>
            <div class="profile-title mb-2 accent-alt4-text">
                ACCOUNT INFORMATION
            </div>
            <div class="row mb-0 mb-lg-5">
                <div class="col-12 col-lg-6 mb-4">
                    <div class="form-group">
                        <label class="mb-2 accent-main-text">Login ID</label>
                        <input type="text" class="form-control" placeholder="Login ID" disabled
                            [value]="userProfileData.login_id">
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-4">
                    <div class="form-group">
                        <label class="mb-2 accent-main-text">Nick Name</label>
                        <input type="text" class="form-control" placeholder="Nick Name" [(ngModel)]="nickname"
                            name="nickname">
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-4">
                    <div class="form-group">
                        <label class="mb-2 accent-main-text">Email</label>
                        <input type="email" email="true" class="form-control" placeholder="Email" [(ngModel)]="email"
                            name="email">
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-4">
                    <div class="form-group">
                        <label class="mb-2 accent-main-text">Nomor Telpon</label>
                        <input type="text" class="form-control" placeholder="Nomor Telpon" disabled
                            [value]="userProfileData.mobile_phone_number">
                    </div>
                </div>
            </div>
            <button type="submit"
                [class]="'btn submit mt-2 mb-4 mt-lg-0 mb-lg-0 button-primary button-primary-bg-'+currentTheme"
                [disabled]="isSubmit">
                @if(isSubmit) {
                <div class="spinner-border p-2 spinner-border-sm"></div>
                } @else {
                SAVE
                }
            </button>
            <hr class="my-5">
            <div class="profile-title accent-alt4-text">
                SECURITY & PASSWORD
            </div>
            <div class="mb-4 accent-main-text">
                For your security, we highly recomended that you choose a unique password that you don’t use for any
                other online
                account
            </div>
            <div class="mb-2 accent-alt4-text">Change Password</div>
            <div [class]="'btn mb-4 button-primary button-primary-bg-'+currentTheme" (click)="popupOpen('password')">
                CHANGE PASSWORD
            </div>
            <div class="mb-2 accent-alt4-text">PIN</div>
            <div [class]="'btn mb-4 button-primary button-primary-bg-'+currentTheme" (click)="popupOpen('pin')">
                PIN
            </div>
        </div>
    </form>
    <app-footer [data]="footerData" />
</div>
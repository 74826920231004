@if(currentScreenSize === 'desktop') {
<div class="header-container {{currentScreenSize}}">
    <div class="header-container-inner"
        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
        <div class="header-container-inner-wrapper container-xxl m-auto">
            <div class="header-middle">
                <div class="header-middle-left">
                    <div class="header-middle-left-inner">
                        <a routerLink="/" class="link-casino">
                            <img [src]="websiteJSONData.header.logo" alt="logo" />
                        </a>
                    </div>
                </div>
                <div class="header-middle-center">
                    <div [class]="'latest-news fs-12 fw-600 accent-primary-bg-'+currentTheme">
                        <div [class]="'latest-news-left accent-primary-text'">
                            <fa-icon [icon]="faVolumeHigh" class="me-1"></fa-icon> LATEST NEWS
                        </div>
                        <div class="latest-news-right accent-secondary">
                            <div class="latest-news-right-inner">
                                @if(newstickerData !== null) {
                                @for (item of newstickerData; track item._id; let idx = $index, e = $even) {
                                    @if(item.link_type === 1 && item.article !== '') {
                                        <a class="newsticker-item" [routerLink]="['/promotion']" [queryParams]="{_id: item.article}">
                                            {{item.title}}
                                        </a>
                                    } @else if(item.target_url !== '' && item.target_url !== '#' && item.target_url !== null) {
                                        <a class="newsticker-item" href={{item.target_url}} target="_blank">{{item.title}}</a>
                                    } @else {
                                        <span class="newsticker-item">{{item.title}}</span>
                                    }
                                @if (idx < (newstickerData.length - 1)) { <span class="marquee-sep"></span>
                                    }
                                    }
                                    }
                            </div>
                        </div>
                    </div>
                    <div class="time fs-10 fw-500 accent-main-text">
                        <fa-icon [icon]="faClock"></fa-icon> {{ time }}
                    </div>
                </div>
                <div class="header-middle-right">
                    @if(!islogin) {
                    <a routerLink="/register">
                        <button [class]="'btn-register fs-14 fw-400 button-primary button-primary-bg-'+currentTheme">
                            REGISTER
                        </button>
                    </a>
                    <a (click)="redirectLogin()">
                        <button class="btn-login fs-14 fw-400 button-secondary">
                            LOGIN
                        </button>
                    </a>
                    } @else {
                    <div ngbDropdown class="profile-dropdown">
                        <div ngbDropdownToggle>
                            <div class="profile">
                                <div class="name">{{profile.login_id
                                    }}</div>
                                <div class="balance accent-alt4-text">
                                    @if(balance < 0) { <div class="spinner-border p-2 spinner-border-sm">
                                </div>
                                } @else {
                                IDR {{balance | formatNumberId}}
                                }
                            </div>
                        </div>
                        <img src="../assets/orianna/images/avatar.png" alt="avatar" />
                    </div>
                    <div ngbDropdownMenu>
                        <a routerLink="/profile">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faUser"></fa-icon> Profile
                            </button>
                        </a>
                        <a routerLink="/statement">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faBook"></fa-icon> Statement
                            </button>
                        </a>
                        <a routerLink="/deposit">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faWallet"></fa-icon> Deposit
                            </button>
                        </a>
                        <a routerLink="/withdrawal">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faDownload"></fa-icon> Withdrawal
                            </button>
                        </a>
                        <a routerLink="/promotion">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faPercent"></fa-icon> Promosi
                            </button>
                        </a>
                        @if(!islogin && referralRuleJSONData['1'] !== undefined || islogin &&
                        referralRuleJSONData[userGorupData['rank']]
                        !== undefined) {
                        <a routerLink="/referral">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faNetworkWired"></fa-icon> Referral
                            </button>
                        </a>
                        }
                        <a routerLink="/profile" [queryParams]="{ action: 'change-password' }">
                            <button ngbDropdownItem>
                                <fa-icon [icon]="faLock"></fa-icon> Ganti Password
                            </button>
                        </a>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="logout()">
                            <fa-icon [icon]="faArrowRightFromBracket"></fa-icon> Keluar
                        </button>
                    </div>
                </div>
                }
            </div>
        </div>
        @if(!islogin) {
        <div class="header-bottom fs-10 fw-400">
            <a href="#" class="accent-alt4-text">
                Forgot password?
            </a>
        </div>
        } @else {
        <div class="header-bottom fs-10">

        </div>
        }
    </div>
</div>
<div [class]="'menu-top-container accent-header-bg-'+currentTheme">
    <div class="menu-top container-xxl m-auto">
        <div class="menu-provider2" [class.d-grid]="showMenuSlots.menu || showMenuSlots.submenu"
            [class.d-none]="!showMenuSlots.menu && !showMenuSlots.submenu" (mouseenter)="showMenu('slots', 'submenu')"
            (mouseleave)="hideMenu('slots', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['slots'].providers; track item._id;) {
            <a [routerLink]="['/category','slots']" [queryParams]="{provider: item.code, time: getTime}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="'../template/mps-basic/assets/images/providers/header/'+currentTheme+'/slot/'+item.code+'.webp'" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-provider2" [class.d-grid]="showMenuLiveCasino.menu || showMenuLiveCasino.submenu"
            [class.d-none]="!showMenuLiveCasino.menu && !showMenuLiveCasino.submenu"
            (mouseenter)="showMenu('live-casino', 'submenu')" (mouseleave)="hideMenu('live-casino', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['live-casino'].providers; track item._id;) {
            <a [routerLink]="['/category','live-casino']" [queryParams]="{provider: item.code}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="'../template/mps-basic/assets/images/providers/header/'+currentTheme+'/live casino/'+item.code+'.webp'" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-provider2" [class.d-grid]="showMenuFishHunter.menu || showMenuFishHunter.submenu"
            [class.d-none]="!showMenuFishHunter.menu && !showMenuFishHunter.submenu"
            (mouseenter)="showMenu('fish-hunter', 'submenu')" (mouseleave)="hideMenu('fish-hunter', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['fish-hunter'].providers; track item._id;) {
            <a [routerLink]="['/category','fish-hunter']" [queryParams]="{provider: item.code}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="'../template/mps-basic/assets/images/providers/header/'+currentTheme+'/arcade/'+item.code+'.webp'" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-provider2" [class.d-grid]="showMenuSportbook.menu || showMenuSportbook.submenu"
            [class.d-none]="!showMenuSportbook.menu && !showMenuSportbook.submenu"
            (mouseenter)="showMenu('sportbook', 'submenu')" (mouseleave)="hideMenu('sportbook', 'submenu')">
            @if(categoryProviderData !== null) {
            @for (item of categoryProviderData['sportbook'].providers; track item._id;) {
            <a [routerLink]="['/category','sportbook']" [queryParams]="{provider: item.code}"
                (click)="hideMenu('all-submenu', '')">
                <img [src]="'../template/mps-basic/assets/images/providers/header/'+currentTheme+'/sportbook/'+item.code+'.webp'" [alt]="item.name" />
            </a>
            }
            }
        </div>
        <div class="menu-top-left fs-15 fw-600">
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'home'">
                <a routerLink="/">
                    <fa-icon [icon]="faHouse"></fa-icon>
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'search'">
                <a [routerLink]="['/search']">
                    ALL GAMES
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'slots'"
                (mouseenter)="showMenu('slots', 'menu')" (mouseleave)="hideMenu('slots', 'menu')">
                <a [routerLink]="['/category','slots']">
                    SLOT
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'live-casino'"
                (mouseenter)="showMenu('live-casino', 'menu')" (mouseleave)="hideMenu('live-casino', 'menu')">
                <a [routerLink]="['/category','live-casino']">
                    CASINO
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'fish-hunter'"
                (mouseenter)="showMenu('fish-hunter', 'menu')" (mouseleave)="hideMenu('fish-hunter', 'menu')">
                <a [routerLink]="['/category','fish-hunter']">
                    ARCADE
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'sportbook'"
                (mouseenter)="showMenu('sportbook', 'menu')" (mouseleave)="hideMenu('sportbook', 'menu')">
                <a [routerLink]="['/category','sportbook']">
                    SPORTBOOK
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'promotion'">
                <a [routerLink]="['/promotion']">
                    PROMOTION
                </a>
            </div>
            <div class="menu-top-left-item" [class.menu-top-left-item-active]="activeMenu === 'deposit'">
                <a [routerLink]="['/deposit']">
                    DEPOSIT
                </a>
            </div>
        </div>
        <div class="menu-top-right fs-12 fw-400">
            <input type="text" class="form-control fs-12 fw-400" placeholder="Search games.."
                [defaultValue]="searchValue" (keyup.enter)="filterSearchChange($event)"
                (keyup)="updateSearchValue($event)">
            <fa-icon [icon]="faMagnifyingGlass" (click)="filterSearchChange($event)" class="button-alt1"></fa-icon>
        </div>
    </div>
</div>
</div>
}
@else if(currentScreenSize === 'mobile') {
<div class="header-container-mobile {{currentScreenSize}}">
    <div class="header-container-mobile-inner" [class.header-container-mobile-inner-fixed]="sidenav"
        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
        <a routerLink="/" class="link-casino">
            <img [src]="websiteJSONData.header.logo" alt="logo" />
        </a>
        <div class="btn-sidenav" (click)="sidenavToggle()">
            @if(!sidenav) {
            <fa-icon [icon]="faBars"></fa-icon>
            } @else {
            <fa-icon [icon]="faXmark"></fa-icon>
            }
        </div>
    </div>
    @if(latestNewsMobile) {
    <div [class]="'latest-news accent-primary-bg-'+currentTheme">
        <div class="latest-news-left">
            <fa-icon [icon]="faVolumeHigh" class="me-1"></fa-icon>
        </div>
        <div class="latest-news-right">
            <div class="latest-news-right-inner fs-8">
                @if(newstickerData !== null) {
                @for (item of newstickerData; track item._id; let idx = $index, e = $even) {
                @if(item.link_type === 1 && item.article !== '') {
                <a class="newsticker-item" [routerLink]="['/promotion']" [queryParams]="{_id: item.article}">
                    {{item.title}}
                </a>
                } @else if(item.target_url !== '' && item.target_url !== '#' && item.target_url !== null) {
                <a class="newsticker-item" href={{item.target_url}} target="_blank">{{item.title}}</a>
                } @else {
                <span class="newsticker-item">{{item.title}}</span>
                }
                @if (idx < (newstickerData.length - 1)) { <span class="marquee-sep"></span>
                    }
                    }
                    }
            </div>
        </div>
    </div>
    }
    <div class="sidenav fs-14" [class.show]="sidenav">
        @if(islogin) {
        <a routerLink="/profile">
            <div class="profile">
                <img src="../assets/orianna/images/avatar.png" alt="avatar" />
                <div class="profile">
                    <div class="name">{{profile.login_id
                        }}</div>
                    <div class="balance">
                        @if(balance < 0) { <div class="spinner-border p-2 spinner-border-sm">
                    </div>
                    } @else {
                    IDR {{balance | formatNumberId}}
                    }
                </div>
            </div>
    </div>
    </a>
    <div class="sidenav-sep"></div>
    }
    <a routerLink="/" class="sidenav-item" (click)="sidenavToggle()">
        HOME
    </a>
    @if(!islogin) {
    <a routerLink="/register" class="sidenav-item" (click)="sidenavToggle()">
        REGISTER
    </a>
    }
    <div class="sidenav-sep"></div>
    <a [routerLink]="['/category','slots']" class="sidenav-item" (click)="sidenavToggle()">
        SLOT
    </a>
    <a [routerLink]="['/category','live-casino']" class="sidenav-item" (click)="sidenavToggle()">
        CASINO
    </a>
    <a [routerLink]="['/category','fish-hunter']" class="sidenav-item" (click)="sidenavToggle()">
        FISH HUNTER
    </a>
    <a [routerLink]="['/category','sportbook']" class="sidenav-item" (click)="sidenavToggle()">
        SPORTBOOK
    </a>
    <div class="sidenav-sep"></div>
    <a routerLink="/statement" class="sidenav-item" (click)="sidenavToggle()">
        STATEMENT
    </a>
    <a routerLink="/deposit" class="sidenav-item" (click)="sidenavToggle()">
        DEPOSIT
    </a>
    <a routerLink="/withdrawal" class="sidenav-item" (click)="sidenavToggle()">
        WITHDRAW
    </a>
    <a routerLink="/promotion" class="sidenav-item" (click)="sidenavToggle()">
        PROMOTION
    </a>
    @if(!islogin && referralRuleJSONData['1'] !== undefined || islogin && referralRuleJSONData[userGorupData['rank']]
    !== undefined) {
    <a routerLink="/referral" class="sidenav-item" (click)="sidenavToggle()">
        REFERRAL
    </a>
    }
    <a routerLink="/contact" class="sidenav-item" (click)="sidenavToggle()">
        KONTAK
    </a>
    @if(!islogin) {
    <button class="login fs-16" (click)="redirectLogin()">
        LOGIN
    </button>
    } @else() {
    <button class="btn btn-danger btn-block" (click)="logout()">
        LOGOUT
    </button>
    }
</div>
<div class="menu-footer-mobile">
    <div class="menu-footer-mobile-inner fs-7">
        @if(!islogin) {
        <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'login'">
            <a (click)="redirectLogin()">
                <fa-icon [icon]="faRightToBracket"></fa-icon>
                <div class="dot">.</div>
            </a>
        </div>
        } @else {
        <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'deposit'">
            <a routerLink="/deposit">
                <fa-icon [icon]="faMoneyBillTransfer"></fa-icon>
                <div class="dot">.</div>
            </a>
        </div>
        }
        <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'promotion'">
            <a routerLink="/promotion">
                <fa-icon [icon]="faGift"></fa-icon>
                <div class="dot">.</div>
            </a>
        </div>
        <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'home'">
            <a routerLink="/">
                <fa-icon [icon]="faHouse"></fa-icon>
                <div class="dot">.</div>
            </a>
        </div>
        <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'contact'">
            <a routerLink="/contact">
                <fa-icon [icon]="faPhone"></fa-icon>
                <div class="dot">.</div>
            </a>
        </div>
        <div class="menu-footer-item" [class.menu-footer-item-active]="activeMenu === 'profile'">
            <a routerLink="/profile">
                <fa-icon [icon]="faUser"></fa-icon>
                <div class="dot">.</div>
            </a>
        </div>
    </div>
</div>
</div>
}
<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">DAFTAR AKUN BANK</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="align-items-center pin-main p-2">
        @if(successMessage !== '') {
        <div class="alert alert-success p-2" role="alert">
            {{successMessage}}
        </div>
        }
        @if(errorMessage !== '') {
        <div class="alert alert-danger p-2" role="alert">
            {{errorMessage}}
        </div>
        }
        <form (submit)="createBankAccount()">
            <div class="form-group mb-3">
                <label class="label text-white">Pilih Bank*</label>
                <select class="form-select" [(ngModel)]="bank" name="bank">
                    <option value="">Pilih Bank</option>
                    @for (item of bankList; track item._id;) {
                    <option [value]="item._id">{{item.name}}</option>
                    }
                </select>
            </div>
            <div class="form-group mb-3">
                <label class="label text-white">Nomor Rekening*</label>
                <input type="text" class="form-control" placeholder="Nomor Rekening" [(ngModel)]="account_number"
                    name="account_number">
            </div>
            <div class="form-group mb-3">
                <label class="label text-white">Penerima*</label>
                <input type="text" class="form-control" placeholder="Penerima" [(ngModel)]="account_name"
                    name="account_name" disabled>
            </div>
            <button [class]="'btn btn-block mt-2 button-primary button-primary-bg-'+currentTheme" type="submit"
                [disabled]="isSubmit">
                @if(isSubmit) {
                <div class="spinner-border p-2 spinner-border-sm"></div>
                } @else {
                Tambah Akun
                }
            </button>
        </form>
    </div>
</div>
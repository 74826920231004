<div class="modal-body p-0">
    @if(currentScreenSize === 'desktop') {
    <div class="d-flex popup-desktop">
        <div ngbNav #nav="ngbNav" [(activeId)]="active" class="flex-column" orientation="vertical">
            @for (popup of popups; track popup;) {
            <ng-container [ngbNavItem]="popup[0].keyword">
                <button ngbNavLink>{{popup[0].keyword}}</button>
                <ng-template ngbNavContent>
                    <div class="popup-title">
                        {{popup[0].keyword}}
                        <div class="icon-close" (click)="close()">
                            <fa-icon class="icon fa-xl" [icon]="faXmark"></fa-icon>
                        </div>
                    </div>
                    @if(popupData === null || popupData !== null && popupData.keyword !== popup[0].keyword) {
                    <div class="popup-body"
                        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
                        @for(item of popup; track item;) {
                        <img [src]="item.asset_url" [alt]="item.title" (click)="popupDetail(item)" />
                        }
                    </div>
                    } @else if(popupData !== null && popupData.keyword === popup[0].keyword) {
                    <div class="popup-body"
                        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
                        <button [class]="'back btn mb-4 button-primary button-primary'+currentTheme"
                            (click)="popupDetail(null)">
                            << Back </button>
                                <img [src]="popupData.asset_url" [alt]="popupData.title" />
                                <div class="popup-content" [innerHTML]="popupData.content"></div>
                    </div>
                    }
                </ng-template>
            </ng-container>
            }
        </div>

        <div [ngbNavOutlet]="nav" class="ms-4"></div>
    </div>
    } @else {
    <div class="popup-mobile">
        <div ngbNav #nav="ngbNav" [(activeId)]="active">
            @for (popup of popups; track popup;) {
            <ng-container [ngbNavItem]="popup[0].keyword">
                @if(popup[0].keyword === 'ALL PROMO & EVENT') {
                <button ngbNavLink>
                    ALL
                </button>
                } @else {
                <button ngbNavLink>
                    {{popup[0].keyword}}
                </button>
                }
                <ng-template ngbNavContent>
                    @if(popupData === null || popupData !== null && popupData.keyword !== popup[0].keyword) {
                    <div class="popup-body"
                        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
                        @for(item of popup; track item;) {
                        <img [src]="item.asset_url" [alt]="item.title" (click)="popupDetail(item)" />
                        }
                        <div class="popup-close" (click)="close()">
                            <fa-icon class="icon fa-xl" [icon]="faXmark"></fa-icon> Close
                        </div>
                    </div>
                    } @else if(popupData !== null && popupData.keyword === popup[0].keyword) {
                    <div class="popup-body"
                        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
                        <button [class]="'back btn mb-4 button-primary button-primary'+currentTheme"
                            (click)="popupDetail(null)">
                            << Back </button>
                                <img [src]="popupData.asset_url" [alt]="popupData.title" />
                                <div class="popup-content" [innerHTML]="popupData.content"></div>
                                <div class="popup-close" (click)="close()">
                                    <fa-icon class="icon" [icon]="faXmark"></fa-icon> Close
                                </div>
                    </div>
                    }
                </ng-template>
            </ng-container>
            }
        </div>

        <div [ngbNavOutlet]="nav" class="ms-4"></div>
    </div>
    }
</div>
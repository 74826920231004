import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, PLATFORM_ID, Inject, Input, inject } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { CardGameComponent } from '@template/mps-basic/parts/card-game/card-game.component';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingPopupComponent } from '@template/mps-basic/parts/loading-popup/loading-popup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-popular-games',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FontAwesomeModule, CardGameComponent, RouterModule],
  templateUrl: './popular-games.component.html',
  styleUrl: './popular-games.component.scss'
})
export class PopularGamesComponent implements OnInit {
  @Input() data: any;
  faAnglesRight: IconProp = faAnglesRight;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private request: RequestService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }
  private modalService = inject(NgbModal);

  currentScreenSize: string = '';
  currentTheme: string = 'gold';

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.99px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.99px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  gameClick(item: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.auth.sessionValue !== '') {
        this.modalService.open(LoadingPopupComponent, { size: 'lg', keyboard: false, centered: true });
        if (window.innerWidth >= 992) {
          this.modalService.dismissAll();
          this.router.navigate(['/play/' + item.id]);
        } else {
          this.request.launchGame(this.auth.sessionValue, item.provider, window.location.origin, item.id, false).subscribe({
            next: (v) => {
              if (v.data !== null) {
                if (item.publisher_code !== 'pg') {
                  this.modalService.dismissAll();
                  const url: any = this.domSanitizer.bypassSecurityTrustResourceUrl(v.data.url as string);
                  // window.open(url.changingThisBreaksApplicationSecurity, "_blank");
                  window.location.href = url.changingThisBreaksApplicationSecurity;
                } else {
                  this.modalService.dismissAll();
                  const domParser = new DOMParser();
                  let htmlElement = domParser.parseFromString(v.data.url as string, 'text/html');
                  const headTag = htmlElement.getElementsByTagName('head')[0];
                  let descriptionWebsiteString = "<meta http-equiv=\"cache-control\" content=\"no-cache, no-store, must-revalidate\" />";
                  let descriptionWebsite = domParser.parseFromString(descriptionWebsiteString, 'text/xml');
                  headTag.prepend(descriptionWebsite.documentElement);
                  const url: any = this.domSanitizer.bypassSecurityTrustHtml(htmlElement.documentElement.outerHTML);
                  // window.open(url.changingThisBreaksApplicationSecurity, "_blank");
                  window.location.href = url.changingThisBreaksApplicationSecurity;
                }
              }
            }
          });
        }
      } else {
        this.router.navigate(['/play/' + item.id]);
      }
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      register();
    }
  }
}

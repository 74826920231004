<!-- <ng-template #devContent let-modal> -->
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">NOTIFIKASI</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body dev-mode-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    Ini adalah website percobaan, semua transaksi yang terjadi tidak berlaku.
</div>
<div class="modal-footer dev-mode-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Save click')">OK</button>
</div>
<!-- </ng-template> -->
<swiper-container class="swiper-container" slides-per-view="1" loop="true" navigation="true" pagination="true"
    autoplay-delay="2500" autoplay-pause-on-mouse-enter="true">
    @if(data.length > 0) {
    @for (item of data; track item.order;) {
    <swiper-slide class="swiper-item">
        @if(item.link_type === 1 && item.article !== '') {
            <a [routerLink]="['/promotion']" [queryParams]="{_id: item.article}">
                <img [src]="item.asset_url" [title]="item.title" />
            </a>
        } @else if(item.target_url !== '' && item.target_url !== '#' && item.target_url !== null) {
            <a [href]="item.target_url" target="_blank">
                <img [src]="item.asset_url" [title]="item.title" />
            </a>
        } @else {
            <img [src]="item.asset_url" [title]="item.title" />
        }
    </swiper-slide>
    }
    } @else {
    <swiper-slide class="swiper-item">
        <img src="../template/mps-basic/assets/orianna/images/Slot Default.webp" title="Slot" />
    </swiper-slide>
    <swiper-slide class="swiper-item">
        <img src="../template/mps-basic/assets/orianna/images/Casino Default.webp" title="Casino" />
    </swiper-slide>
    <swiper-slide class="swiper-item">
        <img src="../template/mps-basic/assets/orianna/images/Arcade Default.webp" title="Arcade" />
    </swiper-slide>
    }
</swiper-container>
import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { FormsModule } from '@angular/forms';
import { faLink, faWallet, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { ReferralPopupClaimComponent } from '@template/mps-basic/parts/referral-popup-claim/referral-popup-claim.component';
import { ReferralPopupDetailComponent } from '@template/mps-basic/parts/referral-popup-detail/referral-popup-detail.component';
import { ReferralPopupEditComponent } from '@template/mps-basic/parts/referral-popup-edit/referral-popup-edit.component';
import { Router } from '@angular/router';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';

@Component({
  selector: 'app-referral',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FormsModule, FontAwesomeModule, FormatNumberIdPipe, CommonModule],
  templateUrl: './referral.component.html',
  styleUrl: './referral.component.scss'
})
export class ReferralComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private router: Router
  ) { }

  private modalService = inject(NgbModal);

  currentTheme: string = 'gold';

  islogin: boolean = false;
  subscription: Subscription = new Subscription();

  faLink: IconProp = faLink;
  faWallet: IconProp = faWallet;
  faCircleInfo: IconProp = faCircleInfo;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  referralRule: any = null;
  userProfileData: any = null;
  baseUrl: string = '';

  getReferralPlayer: any = null;
  getReferralDownline: any = null;

  popupOpen(name: string): void {
    if (name === 'claim') {
      const activeModal = this.modalService.open(ReferralPopupClaimComponent, { size: 'xl', keyboard: false, centered: true });
      activeModal.componentInstance.max = this.getReferralPlayer.data.earning - this.getReferralPlayer.data.claimed_earning;
      activeModal.result.then(
        (result) => {
          this.getReferralPlayer = result;
        }
      );
    } else if (name === 'detail') {
      this.modalService.open(ReferralPopupDetailComponent, { size: 'xl', keyboard: false, centered: true });
    } else if (name === 'edit') {
      const activeModal = this.modalService.open(ReferralPopupEditComponent, { size: 'xl', keyboard: false, centered: true });
      activeModal.result.then(
        (result) => {
          this.request.getReferralPlayer(this.auth.sessionValue, this.userProfileData._id).subscribe({
            next: (v) => {
              this.getReferralPlayer = v;
            },
            error: (e) => {
              console.log(e);
            },
            complete: () => { }
          });
        }
      );
    }
  }

  popupClose() {
    this.modalService.dismissAll();
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.islogin = v;
        if (v && this.userProfileData !== null) {
          this.referralRule = null;
          for (const [key, value] of Object.entries(this.data.referralRuleJSONData)) {
            if (this.userProfileData.group_rank === value.rank) {
              this.referralRule = value;
              break;
            }
          }
        }
      }
    }));
  }

  copyToClipboard(value: string): void {
    navigator.clipboard.writeText(value);
  }
  changeReferralCode(value: string) {
    this.popupOpen('edit');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();

      if (!this.islogin && this.data.referralRuleJSONData['1'] === undefined || this.islogin && this.data.referralRuleJSONData[this.auth.userGorupData['rank']] === undefined) {
        this.router.navigate(['/']);
      } else {
        if (!this.islogin) {
          this.referralRule = this.data.referralRuleJSONData['1'];
        } else {
          this.referralRule = this.data.referralRuleJSONData[this.auth.userGorupData['rank']];
        }
      }

      const parsedUrl = new URL(window.location.href);
      this.baseUrl = parsedUrl.origin;

      this.userProfileData = this.auth.userProfileData;

      this.request.getReferralPlayer(this.auth.sessionValue, this.userProfileData._id).subscribe({
        next: (v) => {
          this.getReferralPlayer = v;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });

      this.request.getReferralDownline(this.auth.sessionValue, this.userProfileData._id, { page: 1, limit: 50 }).subscribe({
        next: (v) => {
          this.getReferralDownline = v;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'referral') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }

}

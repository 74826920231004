<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">PENGAJUAN KLAIM</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="align-items-center">
        <div class="p-4 mb-3 rounded accent-alt1">
            @if(successMessage !== '') {
            <div class="alert alert-success p-2" role="alert">
                {{successMessage}}
            </div>
            }
            @if(errorMessage !== '') {
            <div class="alert alert-danger p-2" role="alert">
                {{errorMessage}}
            </div>
            }
            <form (submit)="claimReferralSubmit()">
                <div class="input-group mb-4">
                    <input type="text" class="form-control accent-alt2" placeholder="Total" name="total" [max]="max"
                        (input)="filterNumber($event)" (keyup)="amountKeyup($event)" [(ngModel)]="total">
                    <span class="input-group-text accent-alt2">
                        <span [class]="'btn btn-sm button-primary button-primary-bg-'+currentTheme"
                            (click)="clickMax()">MAX</span>
                    </span>
                </div>
                <div class="text-end">
                    <button [class]="'btn btn-sm py-1 px-4 button-primary button-primary-bg'+currentTheme" type="submit"
                        [disabled]="loginSubmit">
                        @if(loginSubmit) {
                        <div class="spinner-border p-2 spinner-border-sm"></div>
                        } @else {
                        PROSES
                        }
                    </button>
                </div>
            </form>
        </div>
        <div class="saldo-container">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tanggal</th>
                            <th scope="col">Jumlah Klaim</th>
                        </tr>
                    </thead>
                    <tbody>
                        @if(claimedReferral === null) {
                        <tr>
                            <td colspan="4" align="center">
                                <div class="spinner-border"></div>
                            </td>
                        </tr>
                        } @else if(claimedReferral.data.records.length === 0) {
                        <tr>
                            <td colspan="4" align="center">
                                No Data
                            </td>
                        </tr>
                        } @else {
                        @for (item of claimedReferral.data.records; track item;) {
                        <tr>
                            <td>{{item._id}}</td>
                            <td>{{item.created_at | formatDateId}}</td>
                            <td>IDR {{item.claimed_amount | formatNumberId}}</td>
                        </tr>
                        }
                        }
                    </tbody>
                    <caption>
                        <div class="row">
                            <div class="col-6">
                                @if(claimedReferral !== null) {
                                Showing
                                <b>{{(claimedReferral.data.limit*(claimedReferral.data.page-1))+1}}</b>-<b>{{(claimedReferral.data.limit*claimedReferral.data.page)
                                    > claimedReferral.data.total_records ? claimedReferral.data.total_records :
                                    claimedReferral.data.limit*claimedReferral.data.page}}</b>
                                from
                                <b>{{claimedReferral.data.total_records}}</b> data
                                }
                            </div>
                            <div class="col-6">
                                @if(claimedReferral !== null && claimedReferral.data.max_page > 1) {
                                <ul class="pagination">
                                    <li class="page-item" [class.disabled]="page===1" (click)="pageChange(page-1)">
                                        <button class="page-link">Previous</button>
                                    </li>
                                    @for (item of pagination; track item;) {
                                    <li class="page-item" (click)="pageChange(item)">
                                        <button class="page-link" [class.active]="page===item">{{item}}</button>
                                    </li>
                                    }
                                    <li class="page-item" [class.disabled]="page===claimedReferral.data.max_page"
                                        (click)="pageChange(page+1)">
                                        <button class="page-link">Next</button>
                                    </li>
                                </ul>
                                }
                            </div>
                        </div>
                    </caption>
                </table>
            </div>
        </div>
    </div>
</div>
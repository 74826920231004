import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FileImageBankPipe } from '@componentpath/pipes/slug-name.pipe';
import { FormsModule } from '@angular/forms';
import { faCloudUpload, faImage, faChevronDown, faClose, faCirclePlus, faCircleExclamation, faPercent } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { PromoPopupComponent } from '@template/mps-basic/parts/promo-popup/promo-popup.component';
import { BankCompanyPopupComponent } from '@template/mps-basic/parts/bank-company-popup/bank-company-popup.component';
import { BankPlayerPopupComponent } from '@template/mps-basic/parts/bank-player-popup/bank-player-popup.component';
import { QrisPopupComponent } from '@template/mps-basic/parts/qris-popup/qris-popup.component';
import { DepositPopupUploadComponent } from '@template/mps-basic/parts/deposit-popup-upload/deposit-popup-upload.component';
import { DepositPopupMessageComponent } from '@template/mps-basic/parts/deposit-popup-message/deposit-popup-message.component';
import { NgbModal, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { FormatNumberIdPipe } from '@componentpath/pipes/format-number-id.pipe';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-deposit',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FormsModule, FontAwesomeModule, FileImageBankPipe, NgbAccordionModule, FormatNumberIdPipe, CommonModule],
  templateUrl: './deposit.component.html',
  styleUrl: './deposit.component.scss'
})

export class DepositComponent {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public data: DataService,
    public auth: AuthService,
    public request: RequestService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }
  private modalService = inject(NgbModal);

  currentTheme: string = 'gold';

  qrisEnable: boolean = false;
  qrisFee: string = "";
  qrisFeeFix: string = "";
  qrisCallback: any = null;

  faCloudUpload: IconProp = faCloudUpload;
  faImage: IconProp = faImage;
  faChevronDown: IconProp = faChevronDown;
  faClose: IconProp = faClose;
  faCirclePlus: IconProp = faCirclePlus;
  faCircleExclamation: IconProp = faCircleExclamation;
  faPercent: IconProp = faPercent;

  currentUnix = dayjs().unix();

  bank_info: any = {
    player: null,
    company: null
  };
  promo: any = null;
  userProfileData: any = this.auth.userProfileData;
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  playerBankLoad: any = false;
  playerBankList: any = null;
  companyBankLoad: any = false;
  companyBankList: any = null;
  promoList: any = null;

  isSubmit: boolean = true;
  successMessage: string = '';
  errorMessage: string = '';

  amount: string = '';
  amountNumberFormat: string = '';
  transaction_fee: any = 0;
  transaction_fee_percentage: any = 0;
  transaction_fee_type: any = 'fixed';
  fee: any = 0;
  total: string = '';
  transaction_date: string = '';
  transfer_slip: any = '';
  transaction_note: string = '';
  promotion_type: string = '';
  promotion_id: string = '';

  currentScreenSize: string = '';

  minDeposit: any = null;
  maxDeposit: any = null;
  errDeposit: boolean = false;

  userGorupData: any = this.auth.userGorupData;
  popupEnable: boolean = true;

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  qris(active: boolean) {
    if (active === true) {
      this.updateBankInfo('company', {
        "_id": "qris"
      });
    } else {
      this.updateBankInfoInit();
    }
  }

  promoReset() {
    this.promo = null;
    this.popupEnable = false;
    this.promotion_type = '';
    this.promotion_id = '';
  }

  popupOpen(name: string): void {
    if (this.popupEnable) {
      if (name === 'promo') {
        const activeModal = this.modalService.open(PromoPopupComponent, { size: 'lg', keyboard: false, centered: true });
        activeModal.componentInstance.promoCurrent = this.promo;
        activeModal.result.then(
          (result) => {
            this.promo = result;
            if (this.promo !== null) {
              this.promotion_type = this.promo.type;
              this.promotion_id = this.promo._id;
            } else {
              this.promotion_type = '';
              this.promotion_id = '';
            }
          }
        );
      } else if (name === 'bankCompany') {
        const activeModal = this.modalService.open(BankCompanyPopupComponent, { size: 'lg', keyboard: false, centered: true });
        activeModal.componentInstance.bankCurrent = this.bank_info.company;
        activeModal.componentInstance.bankList = this.companyBankList.data.records;
        activeModal.componentInstance.qrisEnable = this.qrisEnable;
        activeModal.result.then(
          (result) => {
            this.updateBankInfo('company', result);
          }
        );
      } else if (name === 'bankPlayer') {
        const activeModal = this.modalService.open(BankPlayerPopupComponent, { size: 'lg', keyboard: false, centered: true });
        activeModal.componentInstance.bankCurrent = this.bank_info.player;
        activeModal.componentInstance.bankList = this.playerBankList;
        activeModal.result.then(
          (result) => {
            this.updateBankInfo('player', result);
          }
        );
      } else if (name === 'qris') {
        const activeModal = this.modalService.open(QrisPopupComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
        activeModal.componentInstance.qrisCallback = this.qrisCallback;
      } else if (name === 'upload') {
        const activeModal = this.modalService.open(DepositPopupUploadComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
        activeModal.componentInstance.transfer_slip = this.transfer_slip;
        activeModal.result.then(
          (result) => {
            this.transfer_slip = result;
          }
        );
      } else if (name === 'message') {
        const activeModal = this.modalService.open(DepositPopupMessageComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
        activeModal.componentInstance.transaction_note = this.transaction_note;
        activeModal.result.then(
          (result) => {
            this.transaction_note = result;
          }
        );
      }
    } else {
      this.popupEnable = true;
    }
  }

  popupClose() {
    this.modalService.dismissAll();
  }

  updateBankInfo(type: string, value: any): void {
    if (this.playerBankList !== null && this.companyBankList !== null) {
      if (type === 'player') {
        if (this.playerBankList.data.records.length > 0) {
          this.bank_info.player = value;
          this.isSubmit = false;
          this.checkBankCompany();
        }
      } else {
        this.isSubmit = false;
        this.bank_info.company = value;
        this.checkBankCompanyFee();

        if (this.bank_info.company._id === 'qris') {
          this.minDeposit = 10000;
          this.maxDeposit = 10000000;
        } else {
          this.minDeposit = this.bank_info.company.system_bank.min_deposit_amount;
          this.maxDeposit = this.bank_info.company.system_bank.max_deposit_amount;
        }

        this.checkErrDeposit();
      }
    }
  }

  updateBankInfoInit(): void {
    if (this.playerBankList !== null && this.companyBankList !== null) {
      if (this.playerBankList.data.records.length > 0) {
        this.bank_info.player = this.playerBankList.data.records[0];
        this.isSubmit = false;

        this.checkBankCompany();
      }
    }
  }

  checkBankCompany(): void {
    let companySame = null;
    let companyDefault = null;
    let companyFirst = null;
    for (const [index, value] of this.companyBankList.data.records.entries()) {
      if (index === 0) {
        companyFirst = value;
      }

      if (value.group_default) {
        companyDefault = value;
      }

      if (this.bank_info.player.bank === value.bank) {
        companySame = value;
        break;
      }
    }

    this.bank_info.company = companySame !== null ? companySame : companyDefault !== null ? companyDefault : companyFirst;
    this.checkBankCompanyFee();

    this.minDeposit = this.bank_info.company.system_bank.min_deposit_amount;
    this.maxDeposit = this.bank_info.company.system_bank.max_deposit_amount;

    this.checkErrDeposit();
  }

  checkBankCompanyFee(): void {
    if (this.bank_info.company !== null) {
      if (this.bank_info.company._id === 'qris') {
        this.transaction_fee = 0;
        this.transaction_fee_percentage = 0;
      } else {
        if (this.bank_info.company.system_bank !== undefined && this.bank_info.company.system_bank.transaction_fee !== null) {
          this.transaction_fee = this.bank_info.company.system_bank.transaction_fee;
          this.transaction_fee_percentage = this.bank_info.company.system_bank.transaction_fee_percentage;
        } else {
          this.transaction_fee = 0;
          this.transaction_fee_percentage = 0;
        }
      }

      this.getFee();
      this.calcTotal();
    }
  }

  filterNumber(event: any): void {
    event.target.value = event.target.value.replace(/\D/g, '');
  }

  amountKeyup(event: any): void {
    this.amountNumberFormat = event.target.value;
    this.amount = this.amountNumberFormat.replaceAll(/\./g, '');

    if (this.amount !== '') {
      event.target.value = this.numberFormat(parseInt(this.amount));
      this.amountNumberFormat = this.numberFormat(parseInt(this.amount));
    } else {
      event.target.value = this.amount;
    }

    this.getFee();
    this.calcTotal();
    this.checkErrDeposit();
  }

  setAmount(amount: number) {
    this.amount = amount.toString();
    this.amountNumberFormat = this.numberFormat(amount);
    this.getFee();
    this.calcTotal();
    this.checkErrDeposit();
  }

  checkErrDeposit() {
    this.errDeposit = false;

    if (this.minDeposit !== null && this.minDeposit > this.amount) {
      this.errDeposit = true;
    }

    if (this.maxDeposit !== null && this.maxDeposit < this.amount) {
      this.errDeposit = true;
    }

    // if (this.bank_info.company._id === 'qris' && parseInt(this.amount) % 1000 !== 0) {
    //   this.errDeposit = true;
    // }
  }

  numberFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(value);
  }

  getFee() {
    if (this.bank_info.company !== null) {
      if (this.bank_info.company._id !== 'qris') {
        const feePercentage = Math.ceil(this.transaction_fee_percentage * parseInt(this.amount));
        this.fee = feePercentage > this.transaction_fee ? feePercentage : this.transaction_fee;
      } else {
        const feePercentage = Math.ceil(parseFloat(this.qrisFee) * parseInt(this.amount));
        this.fee = feePercentage > this.transaction_fee ? feePercentage : this.qrisFeeFix;
      }
    }
  }

  calcTotal() {
    const total = (isNaN(parseInt(this.amount)) ? 0 : parseInt(this.amount)) - this.fee;
    this.total = 'IDR ' + this.numberFormat((total < 0 ? 0 : total));
  }

  changeTransferSlip(event: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.transfer_slip = reader.result;
    };
  }

  createDepositSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.amount) {
        this.errorMessage = 'Nominal Transaksi is Required.';
        this.isSubmit = false;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return;
      } else {
        if (this.bank_info.company._id === 'qris') {
          if (parseInt(this.amount) < 10000) {
            this.errorMessage = 'Minimal Nominal Transaksi is Rp. 10.000';
            this.isSubmit = false;
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            return;
          } else if (parseInt(this.amount) > 10000000) {
            this.errorMessage = 'Maximum Nominal Transaksi is Rp. 10.000.000';
            this.isSubmit = false;
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            return;
          } else {
            this.request.getIntegrationQR(this.auth.sessionValue, parseInt(this.amount).toString(), '').subscribe({
              next: (v) => {
                this.isSubmit = false;
                this.qrisCallback = v.data;
                this.popupOpen('qris');
              },
              error: (e) => {
                this.isSubmit = false;
                this.errorMessage = e.error.messages[0].message;
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              },
              complete: () => {
                this.amount = '';
              }
            });
          }
        } else {
          this.request.createDeposit(this.auth.sessionValue, this.bank_info.player._id, this.bank_info.company._id, parseInt(this.amount).toString(), this.transaction_date, this.transfer_slip, this.transaction_note, this.promotion_type, this.promotion_id).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.successMessage = 'Create Deposit is Success.';
                this.reset();
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          })
        }
      }
    }
  }

  async downloadQR(parent: any) {
    let link = document.createElement('a');
    link.href = parent.src;
    link.download = parent.alt;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  reset() {
    this.amount = '';
    this.amountNumberFormat = '';
    this.promo = null;
    this.transaction_date = '';
    this.transfer_slip = '';
    this.transaction_note = '';
    this.promotion_type = '';
    this.promotion_id = '';
    this.calcTotal();
  };

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.getIntegrationConfig(this.auth.sessionValue).subscribe({
        next: (v) => {
          if (v.data.qris === true && v.data.status === true) {
            this.qrisEnable = true;
            this.qrisFee = v.data.qris_fee;
            this.qrisFeeFix = v.data.qris_fee_fix_amount;
          }
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });

      this.request.getBankCompanyAccount(this.auth.sessionValue, this.userGorupData._id).subscribe({
        next: (v) => {
          this.companyBankList = v;
          this.companyBankLoad = true;
          this.updateBankInfoInit();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });

      this.request.getBankPlayerList(this.auth.sessionValue, this.userProfileData._id).subscribe({
        next: (v) => {
          this.playerBankList = v;
          this.playerBankLoad = true;
          this.updateBankInfoInit();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'deposit') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
      }
    }
  }
}

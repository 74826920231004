import { Component, inject, PLATFORM_ID, Inject } from '@angular/core';
import { NgbActiveModal, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DataService } from '@componentpath/services/data.service';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [FontAwesomeModule, FormsModule, RouterModule, NgbNavModule, CommonModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss'
})
export class PopupComponent {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    private readonly breakpointObserver: BreakpointObserver,
    public data: DataService,
  ) {
    this.checkScreen();
  }

  faXmark: IconProp = faXmark;
  currentScreenSize: string = '';
  currentTheme: string = 'gold';

  popups: any = [];
  popupData: any = null;
  active: string = '';

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  public close(): void {
    this.activeModal.close();
  }

  public popupDetail(value: any): void {
    this.popupData = value;
  }

  activeModal = inject(NgbActiveModal);

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const popupsJSONData: any = this.data.popupsJSONData;

      if (popupsJSONData !== null) {
        let popupData: any = {};
        popupData['ALL PROMO & EVENT'] = [];
        this.active = 'ALL PROMO & EVENT';
        for (const value of popupsJSONData) {
          if (popupData[value.keyword[0]] === undefined) {
            popupData[value.keyword[0]] = [];
          }

          popupData[value.keyword[0]].push({
            keyword: value.keyword[0],
            title: value.title,
            asset_url: value.asset_url,
            content: value.content
          });
        }

        for (const [key, value] of Object.entries(popupData)) {
          const data: any = value;
          for (const value2 of data) {
            popupData['ALL PROMO & EVENT'].push({
              keyword: 'ALL PROMO & EVENT',
              title: value2.title,
              asset_url: value2.asset_url,
              content: value2.content
            });
          }
        }

        for (const [key, value] of Object.entries(popupData)) {
          if (Array.isArray(value) && value.length > 0) {
            this.popups.push(value);
          }
        }

        if (this.popups.length === 0) {
          this.activeModal.close();
        }
      } else {
        this.activeModal.close();
      }
    }
  }
}

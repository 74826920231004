<div class="page-deposit ticker-none">
    <app-header activeMenu="deposit" [latestNewsMobile]="false" />
    <div class="bg-button-title">
        <div class="button-title fs-20 fw-600 fs-mb-13">
            LIST TRANSACTION
        </div>
    </div>
    <div class="page-inner"
        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/global/'+currentTheme+'/background.webp)'}">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'deposit'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                @if(currentScreenSize === 'desktop') {
                <div class="row mb-4">
                    <div class="col-lg-6">
                        <div class="title">
                            DEPOSIT
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6 pe-2">
                                <div [class]="bank_info.company !== null && bank_info.company._id !== 'qris' ? 'btn btn-outline btn-block p-3 br-10 btn-disable' : 'btn btn-block p-3 br-10 button-primary button-primary-bg-'+currentTheme"
                                    (click)="qris(true)">
                                    <div class="form-check form-switch text-start  fs-18">
                                        <input class="form-check-input" type="checkbox"
                                            [checked]="bank_info.company !== null && bank_info.company._id === 'qris'">
                                        <label class="form-check-label">Auto Deposit QRIS</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 ps-2">
                                <div [class]="bank_info.company !== null && bank_info.company._id === 'qris' ? 'btn btn-outline btn-block p-3 br-10 btn-disable' : 'btn btn-block p-3 br-10 button-primary button-primary-bg-'+currentTheme"
                                    (click)="qris(false)">
                                    <div class="form-check form-switch text-start fs-18">
                                        <input class="form-check-input" type="checkbox"
                                            [checked]="bank_info.company !== null && bank_info.company._id !== 'qris'">
                                        <label class="form-check-label">Bank/VA</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                } @else {
                <div class="row mb-4 px-2">
                    <div class="col-6 px-1">
                        <div [class]="bank_info.company !== null && bank_info.company._id !== 'qris' ? 'btn btn-outline btn-block br-10 fs-12 button-disable' : 'btn btn-block br-10 fs-12 button-primary button-primary-bg-'+currentTheme"
                            (click)="qris(true)">
                            Auto Deposit QRIS
                        </div>
                    </div>
                    <div class="col-6 px-1">
                        <div [class]="bank_info.company !== null && bank_info.company._id === 'qris' ? 'btn btn-outline btn-block br-10 fs-12 button-disable' : 'btn btn-block br-10 fs-12 button-primary button-primary-bg-'+currentTheme"
                            (click)="qris(false)">
                            Bank/VA
                        </div>
                    </div>
                </div>
                }
                @if(companyBankLoad === false || playerBankLoad === false) {
                <div class="text-center text-white pt-4">
                    <div class="spinner-border"></div>
                </div>
                } @else {
                @if(bank_info.company === null) {
                <div class="text-center text-white pt-4">
                    Payment Method is not Available
                </div>
                } @else {
                @if(successMessage !== '') {
                <div class="alert alert-success p-2" role="alert">
                    {{successMessage}}
                </div>
                }
                @if(errorMessage !== '') {
                <div class="alert alert-danger p-2" role="alert">
                    {{errorMessage}}
                </div>
                }
                <form (submit)="createDepositSubmit()">
                    <div class="saldo-container row px-2 my-3">
                        @if(currentScreenSize === 'desktop') {
                        <div class="col-lg-6 col-12 ps-0">
                            @if(bank_info.company !== null && bank_info.company._id !== 'qris') {
                            <div class="card1"
                                [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/deposit/'+currentTheme+'/bg-card.webp)'}">
                                <div class="card-inner">
                                    <div class="row">
                                        <div class="col-lg-6 col-12 mb-lg-0 mb-2 fs-14">
                                            {{bank_info.company.system_bank.name}}
                                        </div>
                                        <div class="col-lg-6 col-12 mt-lg-0 mt-2">
                                            <div class="ml-auto">
                                                <div
                                                    class="bank-image bg-white d-flex justify-content-center align-items-center">
                                                    <img [src]="bank_info.company.system_bank.images.small" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="account-name fs-20">
                                        {{bank_info.company.account_name}}
                                    </div>
                                    <div class="account-number fs-22">
                                        {{bank_info.company.account_number}}
                                    </div>
                                </div>
                                <div class="change-account fs-20" (click)="popupOpen('bankCompany')">
                                    GANTI REKENING TUJUAN
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label class="label text-white mb-1 fs-20">Rekening Anda</label>
                                <div class="input-select-bank" (click)="popupOpen('bankPlayer')">
                                    <button type="button" class="form-control text-start fs-18">
                                        {{bank_info.player.system_bank.name}}
                                        <br />
                                        {{bank_info.player.account_name}} - {{bank_info.player.account_number}}
                                    </button>
                                    <img [src]="bank_info.player.system_bank.images.small" />
                                    <fa-icon [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-6 pe-1 btn-transfer-slip">
                                    <div [class]="transfer_slip === '' ? 'btn btn-inactive btn-block fs-18 button-alt1' : 'btn btn-block fs-18 button-primary button-primary-bg-'+currentTheme"
                                        (click)="popupOpen('upload')">
                                        @if(transfer_slip === '') {
                                        <fa-icon [icon]="faCirclePlus"></fa-icon> Upload Bukti
                                        } @else {
                                        <img [src]="transfer_slip" /> Upload Bukti
                                        }
                                    </div>
                                </div>
                                <div class="col-6 ps-1">
                                    <div [class]="transaction_note === '' ? 'btn btn-inactive btn-block fs-18 button-alt1' : 'btn btn-block fs-18 button-primary button-primary-bg-'+currentTheme"
                                        (click)="popupOpen('message')">
                                        @if(transaction_note === '') {
                                        <fa-icon [icon]="faCirclePlus"></fa-icon> Tambah Catatan
                                        } @else {
                                        Ubah Catatan
                                        }
                                    </div>
                                </div>
                            </div>
                            } @else {
                            <div class="form-group mb-3">
                                <label class="mb-2 text-white d-block">
                                    <span class="fs-20">Nominal Deposit</span>
                                    <span class="float-right fs-14 mt-1">
                                        @if(minDeposit !== null) {
                                        Min : {{minDeposit | formatNumberId}}
                                        }
                                        @if(maxDeposit !== null) {
                                        @if(minDeposit !== null) {
                                        &nbsp;|&nbsp;
                                        }
                                        Max : {{maxDeposit | formatNumberId}}
                                        }
                                    </span>
                                </label>
                                <input type="text"
                                    [class]="errDeposit ? 'form-control input-deposit-amount is-invalid' : 'form-control input-deposit-amount'"
                                    placeholder="Jumlah yang di Transfer" name="amount" (input)="filterNumber($event)"
                                    (keyup)="amountKeyup($event)" [(ngModel)]="amountNumberFormat">
                            </div>
                            <div class="row g-2">
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '10.000' ? 'btn btn-outline btn-block rounded-pill button-disable' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(10000)">{{10000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '20.000' ? 'btn btn-outline btn-block rounded-pill button-disable' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(20000)">{{20000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '50.000' ? 'btn btn-outline btn-block rounded-pill button-disable' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(50000)">{{50000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '100.000' ? 'btn btn-outline btn-block rounded-pill button-disable' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(100000)">{{100000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '200.000' ? 'btn btn-outline btn-block rounded-pill button-disable' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(200000)">{{200000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '500.000' ? 'btn btn-outline btn-block rounded-pill button-disable' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(500000)">{{500000 | formatNumberId}}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="col-lg-6 col-12">
                            @if(bank_info.company !== null && bank_info.company._id !== 'qris') {
                            <div class="form-group mb-3">
                                <label class="mb-2 text-white d-block">
                                    <span class="fs-20">Nominal Deposit</span>
                                    <span class="float-right fs-14 mt-1">
                                        @if(minDeposit !== null) {
                                        Min : {{minDeposit | formatNumberId}}
                                        }
                                        @if(maxDeposit !== null) {
                                        @if(minDeposit !== null) {
                                        &nbsp;|&nbsp;
                                        }
                                        Max : {{maxDeposit | formatNumberId}}
                                        }
                                    </span>
                                </label>
                                <input type="text"
                                    [class]="errDeposit ? 'form-control input-deposit-amount is-invalid' : 'form-control input-deposit-amount'"
                                    placeholder="Jumlah yang di Transfer" name="amount" (input)="filterNumber($event)"
                                    (keyup)="amountKeyup($event)" [(ngModel)]="amountNumberFormat">
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 mb-2 fs-20">
                                    <div [class]="promo === null ? 'btn btn-block btn-outline btn-promo button-disable' : 'btn btn-block btn-promo text-start button-primary button-primary-bg-'+currentTheme"
                                        (click)="popupOpen('promo')">
                                        @if(promo === null) {
                                        <fa-icon [icon]="faPercent" class="me-2"></fa-icon> PILIH PROMO
                                        } @else {
                                        {{promo.title}} <fa-icon [icon]="faClose" class="ms-2"
                                            (click)="promoReset()"></fa-icon>
                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            <div class="form-group mb-2">
                                <div ngbAccordion class="accordion-deposit fs-20">
                                    <div ngbAccordionItem [collapsed]="false" class="mb-0">
                                        <div ngbAccordionHeader>
                                            <button ngbAccordionButton class="p-0">
                                                <div class="row w-100 m-0">
                                                    <div class="col text-yellow p-0 accent-alt4-text">
                                                        <b>Jumlah Topup</b>
                                                    </div>
                                                    <div class="col text-yellow text-end p-0 me-2 accent-alt4-text">
                                                        @if (total === '') {
                                                        <b>IDR 0</b>
                                                        } @else {
                                                        <b>{{total}}</b>
                                                        }
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div ngbAccordionCollapse class="mt-2">
                                            <div ngbAccordionBody class="p-0 accent-alt1-text">
                                                <ng-template>
                                                    <div class="mb-2"><b>Rincian Deposit</b></div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{amount | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{fee | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <hr class="my-2" />
                                                    <div class="row">
                                                        <div class="col">
                                                            Saldo yang Diterima
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        } @else {
                        <div class="col-12 ps-0 mb-3">
                            @if(bank_info.company !== null && bank_info.company._id !== 'qris') {
                            <div class="card1"
                                [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/deposit/'+currentTheme+'/bg-card.webp)'}">
                                <div class="card-inner">
                                    <div class="account-name fs-12">
                                        {{bank_info.company.account_name}}
                                    </div>
                                    <div class="account-number fs-14">
                                        {{bank_info.company.account_number}}
                                    </div>
                                    <div class="image-bank">
                                        <img class="bg-white px-2" [src]="bank_info.company.system_bank.images.small" />
                                    </div>
                                </div>
                                <div class="change-account fs-12 p-2" (click)="popupOpen('bankCompany')">
                                    GANTI REKENING TUJUAN
                                </div>
                            </div>
                            <div class="card-sep my-3 my-lg-4"></div>
                            <div class="form-group mb-2">
                                <label class="label text-white mb-1">Rekening Anda</label>
                                <div class="input-select-bank" (click)="popupOpen('bankPlayer')">
                                    <button type="button" class="form-control text-start fs-10">
                                        {{bank_info.player.system_bank.name}}
                                        <br />
                                        {{bank_info.player.account_name}} - {{bank_info.player.account_number}}
                                    </button>
                                    <img [src]="bank_info.player.system_bank.images.small" />
                                    <fa-icon [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                            <div class="fs-10">
                                <label class="mb-2 text-white d-block">
                                    <span>Nominal Deposit</span>
                                    <span class="float-right">
                                        @if(minDeposit !== null) {
                                        Min : {{minDeposit | formatNumberId}}
                                        }
                                        @if(maxDeposit !== null) {
                                        @if(minDeposit !== null) {
                                        &nbsp;|&nbsp;
                                        }
                                        Max : {{maxDeposit | formatNumberId}}
                                        }
                                    </span>
                                </label>
                            </div>
                            <div class="mb-2">
                                <div class="input-group">
                                    <span class="input-group-text fs-12">IDR</span>
                                    <input type="text" class="form-control text-end fs-11" name="amount"
                                        (input)="filterNumber($event)" (keyup)="amountKeyup($event)"
                                        [(ngModel)]="amountNumberFormat">
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="btn btn-yellow btn-block fs-10" (click)="popupOpen('promo')">
                                    @if(promo === null) {
                                    <fa-icon [icon]="faPercent" class="me-2"></fa-icon> PILIH PROMO
                                    } @else {
                                    {{promo.title}} <fa-icon [icon]="faClose" class="ms-2"
                                        (click)="promoReset()"></fa-icon>
                                    }
                                </div>
                            </div>
                            <div class="row px-2">
                                <div class="col-6 btn-transfer-slip">
                                    <div [class]="transfer_slip === '' ? 'btn btn-inactive btn-block fs-10 button-alt1' : 'btn btn-block fs-10 button-primary button-primary-bg-'+currentTheme"
                                        (click)="popupOpen('upload')">
                                        @if(transfer_slip === '') {
                                        <fa-icon [icon]="faCirclePlus"></fa-icon> Upload Bukti Bayar
                                        } @else {
                                        <img [src]="transfer_slip" /> Upload Bukti Bayar
                                        }
                                    </div>
                                </div>
                                <div class="col-6 fs-10">
                                    <div [class]="transaction_note === '' ? 'btn btn-inactive btn-block fs-10 button-alt1' : 'btn btn-block fs-10 button-primary button-primary-bg-'+currentTheme"
                                        (click)="popupOpen('message')">
                                        @if(transaction_note === '') {
                                        <fa-icon [icon]="faCirclePlus"></fa-icon> Tambah Catatan
                                        } @else {
                                        Ubah Catatan
                                        }
                                    </div>
                                </div>
                            </div>
                            } @else if(bank_info.company !== null && bank_info.company._id === 'qris') {
                            <div class="fs-10">
                                <label class="mb-2 text-white d-block">
                                    <span>Nominal Deposit</span>
                                    <span class="float-right">
                                        @if(minDeposit !== null) {
                                        Min : {{minDeposit | formatNumberId}}
                                        }
                                        @if(maxDeposit !== null) {
                                        @if(minDeposit !== null) {
                                        &nbsp;|&nbsp;
                                        }
                                        Max : {{maxDeposit | formatNumberId}}
                                        }
                                    </span>
                                </label>
                            </div>
                            <div class="mb-2">
                                <div class="input-group">
                                    <span class="input-group-text fs-12">IDR</span>
                                    <input type="text" class="form-control text-end fs-11" name="amount"
                                        (input)="filterNumber($event)" (keyup)="amountKeyup($event)"
                                        [(ngModel)]="amountNumberFormat">
                                </div>
                            </div>
                            <div class="row g-2 mb-2">
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '10.000' ? 'btn btn-outline btn-block rounded-pill fs-10' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(10000)">{{10000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '20.000' ? 'btn btn-outline btn-block rounded-pill fs-10' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(20000)">{{20000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '50.000' ? 'btn btn-outline btn-block rounded-pill fs-10' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(50000)">{{50000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '100.000' ? 'btn btn-outline btn-block rounded-pill fs-10' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(100000)">{{100000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '200.000' ? 'btn btn-outline btn-block rounded-pill fs-10' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(200000)">{{200000 | formatNumberId}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div [class]="amountNumberFormat !== '500.000' ? 'btn btn-outline btn-block rounded-pill fs-10' : 'btn btn-block rounded-pill button-primary button-primary-bg-'+currentTheme"
                                        (click)="setAmount(500000)">{{500000 | formatNumberId}}
                                    </div>
                                </div>
                            </div>
                            <div class="mb-0">
                                <div class="btn btn-yellow btn-block fs-10" (click)="popupOpen('promo')">
                                    @if(promo === null) {
                                    <fa-icon [icon]="faPercent" class="me-2"></fa-icon> PILIH PROMO
                                    } @else {
                                    {{promo.title}} <fa-icon [icon]="faClose" class="ms-2"
                                        (click)="promoReset()"></fa-icon>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                        <div class="col-12">
                            <div class="form-group mb-3">
                                <div ngbAccordion class="accordion-deposit fs-12">
                                    <div ngbAccordionItem [collapsed]="false" class="mb-0">
                                        <div ngbAccordionHeader>
                                            <button ngbAccordionButton class="p-0">
                                                <div class="row w-100">
                                                    <div class="col text-yellow fs-12">
                                                        Saldo yang Diterima
                                                    </div>
                                                    <div class="col text-white text-end fs-14">
                                                        @if (total === '') {
                                                        IDR 0
                                                        } @else {
                                                        {{total}}
                                                        }
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div ngbAccordionCollapse class="mt-2">
                                            <div ngbAccordionBody>
                                                <ng-template>
                                                    <b>Rincian Deposit</b>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{amount | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{fee | formatNumberId}}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di dapat
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        @if(currentScreenSize === 'desktop' && bank_info.company !== null && bank_info.company._id ===
                        'qris') {
                        <div [class]="promo === null ? 'btn btn-block btn-outline btn-promo button-disable' : 'btn btn-block btn-promo mt-4 text-start button-primary button-primary-bg-'+currentTheme"
                            (click)="popupOpen('promo')">
                            @if(promo === null) {
                            <fa-icon [icon]="faPercent" class="me-2"></fa-icon> PILIH PROMO
                            } @else {
                            {{promo.title}} <fa-icon [icon]="faClose" class="ms-2" (click)="promoReset()"></fa-icon>
                            }
                        </div>
                        }
                        <button type="submit"
                            [class]="'btn btn-block btn-submit-deposit fs-21 fs-mb-14 mt-lg-4 mt-0 button-primary button-primary-bg-'+currentTheme"
                            [disabled]="isSubmit || bank_info === null || errDeposit">
                            Buat Deposit
                        </button>
                    </div>
                </form>
                }
                }
            </div>
        </div>
    </div>
    @if(currentScreenSize === 'desktop') {
    <app-footer [data]="footerData" />
    }
</div>
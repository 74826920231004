import { isPlatformBrowser, CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dev-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dev-popup.component.html',
  styleUrl: './dev-popup.component.scss'
})
export class DevPopupComponent implements OnInit, AfterViewInit {
  @ViewChild('devContent') devContent!: TemplateRef<any>;
  private modalService = inject(NgbModal);
  closeResult = '';
  currentTheme: string = 'gold';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    // this.open(this.devContent);
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // this.modalService.open(this.devContent, { centered: true });
    }
  }

  activeModal = inject(NgbActiveModal);

}

import {
  Component, inject, PLATFORM_ID, Inject, ViewChild, ElementRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';

@Component({
  selector: 'app-pin-popup',
  standalone: true,
  imports: [FontAwesomeModule, FormsModule, RouterModule, CommonModule],
  templateUrl: './pin-popup.component.html',
  styleUrl: './pin-popup.component.scss'
})
export class PinPopupComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  pin: string[] = ['', '', '', '', '', ''];
  errorMessage: string = '';
  loginSubmit: boolean = false;
  currentTheme: string = 'gold';

  @ViewChild('pin1') pin1Element!: ElementRef<HTMLInputElement>;
  @ViewChild('pin2') pin2Element!: ElementRef<HTMLInputElement>;
  @ViewChild('pin3') pin3Element!: ElementRef<HTMLInputElement>;
  @ViewChild('pin4') pin4Element!: ElementRef<HTMLInputElement>;
  @ViewChild('pin5') pin5Element!: ElementRef<HTMLInputElement>;
  @ViewChild('pin6') pin6Element!: ElementRef<HTMLInputElement>;

  pinKeydown(event: any, index: number): void {
    if (event.keyCode === 8 && event.target.value === '' && index === 2) {
      this.pin1Element.nativeElement.focus();
    } else if (event.keyCode === 8 && event.target.value === '' && index === 3) {
      this.pin2Element.nativeElement.focus();
    } else if (event.keyCode === 8 && event.target.value === '' && index === 4) {
      this.pin3Element.nativeElement.focus();
    } else if (event.keyCode === 8 && event.target.value === '' && index === 5) {
      this.pin4Element.nativeElement.focus();
    } else if (event.keyCode === 8 && event.target.value === '' && index === 6) {
      this.pin5Element.nativeElement.focus();
    }
  }

  public pinInput(event: any, index: number): void {
    if (event.target.value.length > 0 && parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= 9) {
      this.pin[index - 1] = event.target.value;
      if (index === 1) {
        setTimeout(() => {
          this.pin2Element.nativeElement.focus();
        }, 100);
      } else if (index === 2) {
        setTimeout(() => {
          this.pin3Element.nativeElement.focus();
        }, 100);
      } else if (index === 3) {
        setTimeout(() => {
          this.pin4Element.nativeElement.focus();
        }, 100);
      } else if (index === 4) {
        setTimeout(() => {
          this.pin5Element.nativeElement.focus();
        }, 100);
      } else if (index === 5) {
        setTimeout(() => {
          this.pin6Element.nativeElement.focus();
        }, 100);
      } else if (index === 6) {
        setTimeout(() => {
          this.login();
        }, 100);
      }
    }
  }

  public pinPaste(event: any): void {
    for (let i = 0; i < event.clipboardData.getData('text').length; i++) {
      this.pin[i] = event.clipboardData.getData('text')[i];

      if (i === 0) {
        this.pin1Element.nativeElement.value = event.clipboardData.getData('text')[i];
      } else if (i === 1) {
        this.pin2Element.nativeElement.value = event.clipboardData.getData('text')[i];
      } else if (i === 2) {
        this.pin3Element.nativeElement.value = event.clipboardData.getData('text')[i];
      } else if (i === 3) {
        this.pin4Element.nativeElement.value = event.clipboardData.getData('text')[i];
      } else if (i === 4) {
        this.pin5Element.nativeElement.value = event.clipboardData.getData('text')[i];
      } else if (i === 5) {
        this.pin6Element.nativeElement.value = event.clipboardData.getData('text')[i];
        this.login();
        break;
      }
    }
  }

  public login(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.errorMessage = '';
      this.loginSubmit = true;
      if (!this.pin[0] || !this.pin[1] || !this.pin[2] || !this.pin[3] || !this.pin[4] || !this.pin[5]) {
        setTimeout(() => {
          this.errorMessage = 'PIN is Required.';
          this.loginSubmit = false;
        }, 500);
        return;
      } else {
        const userSecurityData: any = this.auth.userSecurityData;
        if (this.pin.join('') !== userSecurityData.pin) {
          setTimeout(() => {
            this.errorMessage = 'PIN is Not Valid.';
            this.loginSubmit = false;
          }, 500);
          return;
        } else {
          this.auth.setPINStatus(false);
          this.activeModal.dismiss();
          const activatedRoute: any = this.activatedRoute;
          if (activatedRoute.queryParams._value.url !== undefined) {
            this.router.navigate([activatedRoute.queryParams._value.url]);
          } else {
            this.router.navigate(['/']);
          }
        }
      }
    }
  }

  // logout(): void {
  //   this.auth.setLogout();
  //   this.router.navigate(['/']);
  // }

  public logout(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.logout(this.auth.sessionValue).subscribe({
        next: (response) => {
          if (response.code === 200 && response.data.status) {
            this.auth.setLogout();
          } else {
            console.error('Logout failed:', response.message);
          }
        },
        error: (error) => {
          console.error('Logout error:', error);
        },
        complete: () => {
          window.location.reload();
          // this.router.navigate(['/']);
          // console.log(this.auth.sessionValue);
        },
      });
    }
  }

  activeModal = inject(NgbActiveModal);
}

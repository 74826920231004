<div class="popular-games align-items-center"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/global/'+currentTheme+'/background.webp)'}">
    <div class="container-xxl popular-game-container">
        @if(currentScreenSize === 'desktop') {
        <div class="popular-games-left me-5">
            <img [src]="'../template/mps-basic/assets/section/home/popular-games/'+currentTheme+'/popular-games.webp'">
        </div>
        }
        <div class="popular-games-right">
            <a routerLink="/search" [queryParams]="{ sort: 'popularity_point' }">
                <div class="button-title fs-20 fw-600 fs-mb-13">POPULAR GAMES</div>
            </a>
            <div class="games">
                @for (item of data; track item.id;) {
                <a [routerLink]="['/play',item.id]" role="button" target="_blank">
                    <img [src]="item.image" [alt]="item.title">
                </a>
                }
            </div>
            <div class="see-all fs-18 fw-400 fs-mb-10">
                <a routerLink="/search" [queryParams]="{ sort: 'popularity_point' }">
                    SEE ALL <fa-icon [icon]="faAnglesRight"></fa-icon>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">LOGIN</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="row align-items-center">
        @if(currentScreenSize === 'desktop') {
        <div class="col-lg-6 p-0">
            <img src="../template/mps-basic/assets/orianna/images/image-login.png" />
        </div>
        }
        <div class="col-lg-6 col-12 p-5">
            @if(errorMessage !== '') {
            <div class="alert alert-danger p-2" role="alert">
                {{errorMessage}}
            </div>
            }
            <form (submit)="login()">
                <div class="my-2">
                    <input type="text" class="form-control" placeholder="Username" [(ngModel)]="username"
                        name="username" required>
                </div>
                <div class="mb-3 password">
                    <input type="password" class="form-control" [type]="isPasswordVisible ? 'text' : 'password'"
                        placeholder="Password" [(ngModel)]="password" name="password" required>
                    <fa-icon (click)="togglePasswordVisibility()" [icon]="isPasswordVisible ? faEyeSlash : faEye"
                        class="fa-1x"></fa-icon>
                </div>

                <div class="mb-3 d-flex flex-column justify-content-center login-bottom">
                    <button type="submit"
                        [class]="'btn btn-primary btn-block my-3 button-primary button-primary-bg-'+currentTheme"
                        [disabled]="loginSubmit">
                        @if(loginSubmit) {
                        <div class="spinner-border p-2 spinner-border-sm"></div>
                        } @else {
                        LOGIN
                        }
                    </button>
                    <span class="text-white text-center fs-12">
                        Tidak memiliki akun? <a routerLink="/register" class="accent-main-text">Buat Akun</a>
                    </span>
                    <span class="text-white text-center fs-12">
                        <a href="#" class="accent-main-text">Lupa Sandi?</a>
                    </span>
                </div>
            </form>
        </div>
    </div>
</div>
import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, PLATFORM_ID, Inject, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { register } from 'swiper/element/bundle';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-slideshow1',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [RouterModule],
  templateUrl: './slideshow1.component.html',
  styleUrl: './slideshow1.component.scss'
})
export class Slideshow1Component implements OnInit {
  @Input() data: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('data : ', this.data);
      register();
    }
  }
}

import { Component, Inject, PLATFORM_ID, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { DataService } from '@componentpath/services/data.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faLine, faLinkedin, faReddit, faTelegram, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHeadphones, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule, CommonModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private cdr: ChangeDetectorRef
  ) { }

  @ViewChild('infoFacebook') infoFacebook!: ElementRef;
  @ViewChild('valueFacebook') valueFacebook!: ElementRef;
  @ViewChild('infoInstagram') infoInstagram!: ElementRef;
  @ViewChild('valueInstagram') valueInstagram!: ElementRef;
  @ViewChild('infoLine') infoLine!: ElementRef;
  @ViewChild('valueLine') valueLine!: ElementRef;
  @ViewChild('infoLinkedin') infoLinkedin!: ElementRef;
  @ViewChild('valueLinkedin') valueLinkedin!: ElementRef;
  @ViewChild('infoLivechat') infoLivechat!: ElementRef;
  @ViewChild('valueLivechat') valueLivechat!: ElementRef;
  @ViewChild('infoPhone') infoPhone!: ElementRef;
  @ViewChild('valuePhone') valuePhone!: ElementRef;
  @ViewChild('infoReddit') infoReddit!: ElementRef;
  @ViewChild('valueReddit') valueReddit!: ElementRef;
  @ViewChild('infoTelegram') infoTelegram!: ElementRef;
  @ViewChild('valueTelegram') valueTelegram!: ElementRef;
  @ViewChild('infoTwitter') infoTwitter!: ElementRef;
  @ViewChild('valueTwitter') valueTwitter!: ElementRef;
  @ViewChild('infoWhatsapp') infoWhatsapp!: ElementRef;
  @ViewChild('valueWhatsapp') valueWhatsapp!: ElementRef;
  @ViewChild('infoYoutube') infoYoutube!: ElementRef;
  @ViewChild('valueYoutube') valueYoutube!: ElementRef;

  currentTheme: string = 'gold';

  infoFacebookWidth: number = 0;
  valueFacebookWidth: number = 0;
  infoInstagramWidth: number = 0;
  valueInstagramWidth: number = 0;
  infoLineWidth: number = 0;
  valueLineWidth: number = 0;
  infoLinkedinWidth: number = 0;
  valueLinkedinWidth: number = 0;
  infoLivechatWidth: number = 0;
  valueLivechatWidth: number = 0;
  infoPhoneWidth: number = 0;
  valuePhoneWidth: number = 0;
  infoRedditWidth: number = 0;
  valueRedditWidth: number = 0;
  infoTelegramWidth: number = 0;
  valueTelegramWidth: number = 0;
  infoTwitterWidth: number = 0;
  valueTwitterWidth: number = 0;
  infoWhatsappWidth: number = 0;
  valueWhatsappWidth: number = 0;
  infoYoutubeWidth: number = 0;
  valueYoutubeWidth: number = 0;

  faFacebook: IconProp = faFacebook;
  faInstagram: IconProp = faInstagram;
  faLine: IconProp = faLine;
  faLinkedin: IconProp = faLinkedin;
  faReddit: IconProp = faReddit;
  faTelegram: IconProp = faTelegram;
  faTwitter: IconProp = faTwitter;
  faWhatsapp: IconProp = faWhatsapp;
  faYoutube: IconProp = faYoutube;
  faHeadphones: IconProp = faHeadphones;
  faPhone: IconProp = faPhone;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];
  websiteJSONData: any = this.data.websiteJSONData;

  livechatURL: string = '';

  public setLivechatURL() {
    const livechatData = this.websiteJSONData['contact']['livechat'];
    let engineName = (livechatData['engine']).toLowerCase();
    if (engineName === 1 || engineName === 'livechatinc' || engineName === 'livechat' || engineName === 'livechat.com') {
      if (livechatData['code']) {
        this.livechatURL = "https://direct.lc.chat/" + livechatData['code'] + "/" + livechatData['group'];
      }
    }
    if (engineName === 2 || engineName === 'purechat') {
      this.livechatURL = "https://app.purechat.com/w/" + livechatData['pagename'];
    }
    if (engineName === 3 || engineName === 'tawk.to') {
      if (livechatData['pagename']) {
        this.livechatURL = "https://tawk.to/" + livechatData['pagename'];
      } else {
        this.livechatURL = "https://tawk.to/chat/" + livechatData['code'];
      }
    }

    this.redirectToLivechat();
  }

  public redirectToLivechat() {
    window.open(this.livechatURL, '_blank');
  }

  ngAfterViewInit() {
    this.infoFacebookWidth = this.infoFacebook !== undefined ? this.infoFacebook.nativeElement.clientWidth : 0;
    this.valueFacebookWidth = this.valueFacebook !== undefined ? this.valueFacebook.nativeElement.clientWidth : 0;
    this.infoInstagramWidth = this.infoInstagram !== undefined ? this.infoInstagram.nativeElement.clientWidth : 0;
    this.valueInstagramWidth = this.valueInstagram !== undefined ? this.valueInstagram.nativeElement.clientWidth : 0;
    this.infoLineWidth = this.infoLine !== undefined ? this.infoLine.nativeElement.clientWidth : 0;
    this.valueLineWidth = this.valueLine !== undefined ? this.valueLine.nativeElement.clientWidth : 0;
    this.infoLinkedinWidth = this.infoLinkedin !== undefined ? this.infoLinkedin.nativeElement.clientWidth : 0;
    this.valueLinkedinWidth = this.valueLinkedin !== undefined ? this.valueLinkedin.nativeElement.clientWidth : 0;
    this.infoLivechatWidth = this.infoLivechat !== undefined ? this.infoLivechat.nativeElement.clientWidth : 0;
    this.valueLivechatWidth = this.valueLivechat !== undefined ? this.valueLivechat.nativeElement.clientWidth : 0;
    this.infoPhoneWidth = this.infoPhone !== undefined ? this.infoPhone.nativeElement.clientWidth : 0;
    this.valuePhoneWidth = this.valuePhone !== undefined ? this.valuePhone.nativeElement.clientWidth : 0;
    this.infoRedditWidth = this.infoReddit !== undefined ? this.infoReddit.nativeElement.clientWidth : 0;
    this.valueRedditWidth = this.valueReddit !== undefined ? this.valueReddit.nativeElement.clientWidth : 0;
    this.infoTelegramWidth = this.infoTelegram !== undefined ? this.infoTelegram.nativeElement.clientWidth : 0;
    this.valueTelegramWidth = this.valueTelegram !== undefined ? this.valueTelegram.nativeElement.clientWidth : 0;
    this.infoTwitterWidth = this.infoTwitter !== undefined ? this.infoTwitter.nativeElement.clientWidth : 0;
    this.valueTwitterWidth = this.valueTwitter !== undefined ? this.valueTwitter.nativeElement.clientWidth : 0;
    this.infoWhatsappWidth = this.infoWhatsapp !== undefined ? this.infoWhatsapp.nativeElement.clientWidth : 0;
    this.valueWhatsappWidth = this.valueWhatsapp !== undefined ? this.valueWhatsapp.nativeElement.clientWidth : 0;
    this.infoYoutubeWidth = this.infoYoutube !== undefined ? this.infoYoutube.nativeElement.clientWidth : 0;
    this.valueYoutubeWidth = this.valueYoutube !== undefined ? this.valueYoutube.nativeElement.clientWidth : 0;

    this.cdr.detectChanges();


  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.infoFacebookWidth = this.infoFacebook.nativeElement.clientWidth;
    this.valueFacebookWidth = this.valueFacebook.nativeElement.clientWidth;
    this.infoInstagramWidth = this.infoInstagram.nativeElement.clientWidth;
    this.valueInstagramWidth = this.valueInstagram.nativeElement.clientWidth;
    this.infoLineWidth = this.infoLine.nativeElement.clientWidth;
    this.valueLineWidth = this.valueLine.nativeElement.clientWidth;
    this.infoLinkedinWidth = this.infoLinkedin.nativeElement.clientWidth;
    this.valueLinkedinWidth = this.valueLinkedin.nativeElement.clientWidth;
    this.infoLivechatWidth = this.infoLivechat.nativeElement.clientWidth;
    this.valueLivechatWidth = this.valueLivechat.nativeElement.clientWidth;
    this.infoPhoneWidth = this.infoPhone.nativeElement.clientWidth;
    this.valuePhoneWidth = this.valuePhone.nativeElement.clientWidth;
    this.infoRedditWidth = this.infoReddit.nativeElement.clientWidth;
    this.valueRedditWidth = this.valueReddit.nativeElement.clientWidth;
    this.infoTelegramWidth = this.infoTelegram.nativeElement.clientWidth;
    this.valueTelegramWidth = this.valueTelegram.nativeElement.clientWidth;
    this.infoTwitterWidth = this.infoTwitter.nativeElement.clientWidth;
    this.valueTwitterWidth = this.valueTwitter.nativeElement.clientWidth;
    this.infoWhatsappWidth = this.infoWhatsapp.nativeElement.clientWidth;
    this.valueWhatsappWidth = this.valueWhatsapp.nativeElement.clientWidth;
    this.infoYoutubeWidth = this.infoYoutube.nativeElement.clientWidth;
    this.valueYoutubeWidth = this.valueYoutube.nativeElement.clientWidth;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'contact') {
          if (value.contents.id !== '') {
            if (value.contents.id.indexOf('<h1>') === 0 && value.contents.id.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.id.substring(4, value.contents.id.indexOf('</h1>')),
                content: value.contents.id.substring(value.contents.id.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.id
              }
            }

          } else {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
      }
    }
  }
}

<div class="modal-header">
    <h4 class="modal-title fs-20 fw-600 fs-mb-14">Upload Bukti Bayar</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body"
    [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
    <div class="align-items-center"
        [ngStyle]="{'background-image': 'url(../template/mps-basic/assets/section/header/'+currentTheme+'/background.jpg)'}">
        <div class="p-4 mb-3 rounded">
            <div class="row mb-4 fs-12">
                <div class="col-12 mb-3">
                    <div class="btn-upload cursor-pointer" (click)="file_input.click()">
                        <fa-icon [icon]="faCloudUpload"></fa-icon>
                        <div [class]="'mt-2 btn btn-block button-primary button-primary-bg-'+currentTheme">
                            Upload Bukti Bayar
                        </div>
                    </div>
                    <input class="d-none" type="file" accept="image/*" #file_input
                        (change)="changeTransferSlip($event)">
                </div>
                <div class="col-12 mb-3">
                    <div class="btn-image">
                        @if(transfer_slip === '') {
                        <fa-icon [icon]="faImage"></fa-icon>
                        } @else {
                        <img [src]="transfer_slip" />
                        }
                    </div>
                </div>
                <div class="col-12">
                    <div [class]="'btn btn-block button-primary button-primary-bg-'+currentTheme"
                        (click)="popupClose()">
                        Simpan
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCloudUpload, faImage } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-deposit-popup-upload',
  standalone: true,
  imports: [FormsModule, FontAwesomeModule, CommonModule],
  templateUrl: './deposit-popup-upload.component.html',
  styleUrl: './deposit-popup-upload.component.scss'
})
export class DepositPopupUploadComponent {
  constructor() { }

  currentTheme: string = 'gold';

  @Input() transfer_slip: any = null;
  modal = inject(NgbActiveModal);

  faCloudUpload: IconProp = faCloudUpload;
  faImage: IconProp = faImage;

  popupClose() {
    this.modal.close(this.transfer_slip);
  }

  changeTransferSlip(event: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.transfer_slip = reader.result;
    };
  }
}
